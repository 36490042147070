import React from "react";

export default function CampusMapBankIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="43"
      viewBox="0 0 36 43"
      fill="none"
    >
      <path
        d="M36 17.623C36 27.356 18 43 18 43S0 27.356 0 17.623 8.059 0 18 0s18 7.89 18 17.623"
        fill="var(--primary-color)"
      />
      <path
        clip-rule="evenodd"
        d="M28.263 27.273a.2.2 0 0 1-.2.2H7.937a.2.2 0 0 1-.2-.2v-2.758c0-.11.09-.2.2-.2h20.126c.11 0 .2.09.2.2zm0-14.209a.2.2 0 0 1-.2.2H7.937a.2.2 0 0 1-.2-.2v-.98a.2.2 0 0 1 .124-.184l10.124-4.163L28.139 11.9a.2.2 0 0 1 .124.185z"
        stroke="#fff"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.798 23.94V13.722Z"
        fill="#fff"
      />
      <path d="M9.798 23.94V13.722" stroke="#fff" stroke-linecap="square" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.728 23.94V13.722Z"
        fill="#fff"
      />
      <path d="M11.728 23.94V13.722" stroke="#fff" stroke-linecap="square" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.623 23.94V13.722Z"
        fill="#fff"
      />
      <path d="M14.623 23.94V13.722" stroke="#fff" stroke-linecap="square" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.553 23.94V13.722Z"
        fill="#fff"
      />
      <path d="M16.553 23.94V13.722" stroke="#fff" stroke-linecap="square" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.447 23.94V13.722Z"
        fill="#fff"
      />
      <path d="M19.447 23.94V13.722" stroke="#fff" stroke-linecap="square" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.377 23.94V13.722Z"
        fill="#fff"
      />
      <path d="M21.377 23.94V13.722" stroke="#fff" stroke-linecap="square" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.272 23.94V13.722Z"
        fill="#fff"
      />
      <path d="M24.272 23.94V13.722" stroke="#fff" stroke-linecap="square" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.202 23.94V13.722Z"
        fill="#fff"
      />
      <path d="M26.202 23.94V13.722" stroke="#fff" stroke-linecap="square" />
    </svg>
  );
}
