import React from "react";

export default function CampusMapStudentOfficesIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="43"
      viewBox="0 0 36 43"
      fill="none"
    >
      <path
        d="M36 17.623C36 27.356 18 43 18 43S0 27.356 0 17.623 8.059 0 18 0s18 7.89 18 17.623"
        fill="var(--primary-color)"
      />
      <path
        clip-rule="evenodd"
        d="M21.976 6.947c.927 0 1.686.738 1.686 1.64v10.451c0 .901-.759 1.639-1.686 1.639s-1.685-.738-1.685-1.639V8.586c0-.901.759-1.639 1.685-1.639"
        stroke="#fff"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.054 27.21v-2.152h10.448v2.152"
        stroke="#fff"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        clip-rule="evenodd"
        d="M13.56 28.503c0 .686-.573 1.242-1.278 1.242s-1.277-.556-1.277-1.242.572-1.241 1.277-1.241 1.277.556 1.277 1.241m10.449 0c0 .686-.572 1.242-1.277 1.242-.706 0-1.277-.556-1.277-1.242s.572-1.241 1.277-1.241 1.277.556 1.277 1.241"
        stroke="#fff"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.278 25.057v-4.38m4.698 0H12.58c-.927 0-1.685-.737-1.685-1.639 0-.9.758-1.638 1.685-1.638h7.711"
        stroke="#fff"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
