import React from "react";

export default function CampusMapUniversityIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="43"
      viewBox="0 0 36 43"
      fill="none"
    >
      <path
        d="M36 17.623C36 27.356 18 43 18 43S0 27.356 0 17.623 8.059 0 18 0s18 7.89 18 17.623"
        fill="var(--primary-color)"
      />
      <path
        clip-rule="evenodd"
        d="M19.015 21.804 7.42 15.406l11.594-6.4 11.593 6.4z"
        stroke="#fff"
        stroke-width="1.199"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="m19.015 15.405-9.203 1.689-2.128 7.39"
        stroke="#fff"
        stroke-width="1.199"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.478 17.824v6.372c0 1.448-1.156 2.622-2.582 2.622h-11.04c-1.427 0-2.583-1.174-2.583-2.622v-7.101"
        stroke="#fff"
        stroke-width="1.199"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
