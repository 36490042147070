import React, { memo } from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import { Button, ButtonModifier } from "../Button";
import styles from "./WidgetSignUpBar.module.scss";

const cx = createModuleStyleExtractor(styles);

const WidgetSignUpBar = ({
  text = "Ask a question",
  buttonText = "Sign up",
  onSignupClick = () => {},
}) => {
  return (
    <div className={cx("a-widget-sign-up-bar")}>
      <div className={cx("a-widget-sign-up-bar__title")}>{text}</div>
      <Button
        modifier={ButtonModifier.WIDGET_SIGN_UP_BAR}
        onClick={onSignupClick}
      >
        {buttonText}
      </Button>
    </div>
  );
};
export default memo(WidgetSignUpBar);
