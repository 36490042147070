import React, { memo, useState, useEffect } from "react";
import { ShimmerThumbnail } from "react-shimmer-effects";

import { getResourceCategories, getResourcesList } from "../../../api/widget";
import { isNull } from "../../../utils/validations";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./WidgetResources.module.scss";
import Card, { CardModifier } from "../../atoms/Card/Card";
import { Button, ButtonModifier } from "../../atoms/Button/Button";
import ListingEffect from "../../atoms/ListingEffect/ListingEffect";
import Categories from "../../atoms/Categories/Categories";

import BigPlayIcon from "../../../assets/images/BigPlayIcon.png";
import EmptyResourceIcon from "../../../assets/Icons/EmptyResourceIcon";
import FilterIcon from "../../../assets/Icons/FilterIcon";

const cx = createModuleStyleExtractor(styles);

const WidgetResources = ({ schoolData = {}, onSignupClick = () => {} }) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(false);
  const [categoriesLoader, setCategoriesLoader] = useState(false);
  const [resources, setResources] = useState([]);
  const [resourcesLoader, setResourcesLoader] = useState(true);
  const [openFilter, toggleFilter] = useState(false);

  const VISIBLE_RECORDS_COUNT = 5;

  useEffect(() => {
    const fetchResourceCategories = async () => {
      setCategoriesLoader(true);
      const response = await getResourceCategories(schoolData?.token);
      if (response.success) {
        const { video_categories = [] } = response.data;
        setCategories(video_categories);
        if (video_categories.length > 0)
          setSelectedCategory(video_categories[0]);
      } else {
        setCategories([]);
        setSelectedCategory(null);
      }
      setCategoriesLoader(false);
    };

    fetchResourceCategories();
  }, [schoolData?.token]);

  useEffect(() => {
    const fetchServices = async () => {
      setResourcesLoader(true);
      const response = await getResourcesList(
        selectedCategory?.id,
        schoolData?.token
      );
      if (response.success) {
        setResources(response.data?.resources);
      } else {
        setResources([]);
      }
      setResourcesLoader(false);
    };
    if (selectedCategory && !isNull(selectedCategory)) fetchServices();
  }, [selectedCategory, schoolData?.token]);

  return (
    <div className={cx("m-widget-resources")}>
      <div className={cx("m-widget-resources__content")}>
        <div className={cx("m-widget-resources__content__left")}>
          {categoriesLoader ? (
            <>
              {Array.from(Array(5).keys()).map((_, index) => (
                <ShimmerThumbnail
                  key={index}
                  height={60}
                  width={"100%"}
                  rounded
                />
              ))}
            </>
          ) : (
            <div
              className={`collapsible-section ${
                openFilter ? "collapsible-section--open" : ""
              }`}
            >
              <div id={"collapsible-section-header"}>
                <h2 className={cx("m-widget-resources__content__left__title")}>
                  Categories
                </h2>
                <a
                  href="/#"
                  onClick={(e) => {
                    e?.preventDefault && e.preventDefault();
                    toggleFilter(!openFilter);
                  }}
                  id="collapsible-section-header-icon"
                >
                  <FilterIcon fill={openFilter} />
                </a>
              </div>
              <div
                id="collapsible-section-search-filter"
                className={cx("m-widget-resources__content__left__categories")}
              >
                <Categories
                  type="resource"
                  data={categories}
                  selectedCategoryId={
                    selectedCategory && !isNull(selectedCategory)
                      ? selectedCategory.id
                      : null
                  }
                  onCategorySelect={setSelectedCategory}
                />
              </div>
            </div>
          )}
        </div>
        <div className={cx("m-widget-resources__content__right")}>
          <div className={cx("m-widget-resources__content__right__content")}>
            {resourcesLoader ? (
              <ListingEffect count={5} sideSpace={false} />
            ) : (
              <>
                {resources.length > 0 ? (
                  <>
                    {resources.map((resource, index) => (
                      <div
                        key={index}
                        className={cx("m-widget-resources-list-wrapper")}
                      >
                        <Card key={index} modifier={CardModifier.RESOURCES}>
                          {index >= VISIBLE_RECORDS_COUNT && (
                            <div
                              className={cx(
                                "m-widget-resources-list-wrapper__overlay"
                              )}
                            />
                          )}
                          <div
                            className={cx("m-widget-resources-item-wrapper")}
                          >
                            <div className={cx("m-widget-resources-thumbnail")}>
                              {resource.link_type === "Interstride Video" &&
                              resource.interstride_video_thumb_image ? (
                                <>
                                  <img
                                    src={resource.interstride_video_thumb_image}
                                    alt={resource.link_type}
                                    className={cx(
                                      "m-widget-resources-thumbnail-video"
                                    )}
                                  />
                                  <img
                                    src={BigPlayIcon}
                                    alt="play-icon"
                                    className={cx(
                                      "m-widget-resources-thumbnail-video-play-icon"
                                    )}
                                    onClick={() => {}}
                                  />
                                </>
                              ) : resource.link_type === "YouTube Video" &&
                                resource.image ? (
                                <>
                                  <img
                                    src={resource.image}
                                    alt={resource.link_type}
                                    className={cx(
                                      "m-widget-resources-thumbnail-video"
                                    )}
                                  />
                                  <img
                                    src={BigPlayIcon}
                                    alt="play-icon"
                                    className={cx(
                                      "m-widget-resources-thumbnail-video-play-icon"
                                    )}
                                    onClick={() => {}}
                                  />
                                </>
                              ) : resource.link_type === "Website Link" ? (
                                <img
                                  src="https://interstride.s3.amazonaws.com/miscellaneous/assets/media/web_icon.svg"
                                  alt={resource.link_type}
                                  className={cx(
                                    "m-widget-resources-thumbnail-non-video"
                                  )}
                                />
                              ) : resource.link_type === "PDF" ? (
                                <img
                                  src="https://interstride.s3.amazonaws.com/miscellaneous/assets/media/pdf_icon.svg"
                                  alt={resource.link_type}
                                  className={cx(
                                    "m-widget-resources-thumbnail-non-video"
                                  )}
                                />
                              ) : resource.image ? (
                                <img
                                  src={resource.image}
                                  alt={resource.link_type}
                                  className={cx(
                                    "m-widget-resources-thumbnail-image"
                                  )}
                                />
                              ) : (
                                <></>
                              )}
                            </div>
                            <div
                              className={cx("m-widget-resources-description")}
                            >
                              <div
                                className={cx(
                                  "m-widget-resources-description-header-primary"
                                )}
                              >
                                <h3>{resource.title}</h3>
                                <p>{resource.link_type}</p>
                              </div>
                            </div>
                            <div className={cx("m-widget-resources-link")}>
                              <Button
                                modifier={ButtonModifier.RESOURCE}
                                onClick={onSignupClick}
                              >
                                Sign up
                              </Button>
                            </div>
                          </div>
                        </Card>
                      </div>
                    ))}{" "}
                  </>
                ) : (
                  <div className={cx("m-widget-resources-empty")}>
                    <h2>
                      Woops! <br /> We don't have any resource listed right now.
                    </h2>
                    <EmptyResourceIcon />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(WidgetResources);
