import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setChatInfoStatus } from "../../../redux/reducers/preservedReducer";
import { getSelectOptions } from "../../../utils/noop";
import { createModuleStyleExtractor } from "../../../utils/css";
import MuiSelect from "../../atoms/MuiSelect/MuiSelect";
import Switch from "../../atoms/Switch/Switch";
import InfoIconPopup from "../../atoms/InfoIconPopup/InfoIconPopup";
import styles from "./DiscoverNetworkFilters.module.scss";

import FilterIcon from "../../../assets/images/filter-icon.svg";

const cx = createModuleStyleExtractor(styles);

const DiscoverNetworkFilters = ({
  tab = "left",
  networkFilterOptions = {},
  selectedNetworkFilters = {},
  onChangeFilter = () => {},
  onClearFilter = () => {},
  setTab = () => {},
}) => {
  const dispatch = useDispatch();
  const { chatInfoStatus = false } = useSelector(
    (state) => state.preservedState
  );

  const [filtersOpen, setFiltersOpen] = useState(false);
  const [userTypeOptions, setUserTypeOptions] = useState([]);

  const countryOptions = getSelectOptions(networkFilterOptions.countries);
  const degreeOptions = getSelectOptions(networkFilterOptions.degree);
  const majorOptions = getSelectOptions(networkFilterOptions.major);

  useEffect(() => {
    let tabSpecificUserTypes = [...(networkFilterOptions?.user_types || [])];
    setUserTypeOptions(getSelectOptions(tabSpecificUserTypes));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, networkFilterOptions]);

  const filterElements = (
    <div
      className={cx([
        filtersOpen
          ? "m-filters-container__filter__item-small"
          : "m-filters-container__filter__item-large",
      ])}
    >
      {countryOptions?.length > 0 && (
        <div
          className={cx([
            filtersOpen
              ? "m-filters-container__filter__item-small__item"
              : "m-filters-container__filter__item-large__item",
          ])}
        >
          <MuiSelect
            label=""
            placeholder="Filter by country"
            value={
              countryOptions
                ? countryOptions.find(
                    (option) => option.value === selectedNetworkFilters?.country
                  )
                : ""
            }
            options={countryOptions}
            justifyContent
            onChange={(item) =>
              onChangeFilter("country", item ? item.value : null)
            }
            isClearable={true}
          />
        </div>
      )}
      {degreeOptions?.length > 0 && (
        <div
          className={cx([
            filtersOpen
              ? "m-filters-container__filter__item-small__item"
              : "m-filters-container__filter__item-large__item",
          ])}
        >
          <MuiSelect
            label=""
            placeholder="Filter by degree"
            value={
              degreeOptions
                ? degreeOptions.find(
                    (option) => option.value === selectedNetworkFilters?.degree
                  )
                : ""
            }
            options={degreeOptions}
            onChange={(item) =>
              onChangeFilter("degree", item ? item.value : null)
            }
            justifyContent
            isClearable={true}
          />
        </div>
      )}
      {majorOptions?.length > 0 && (
        <div
          className={cx([
            filtersOpen
              ? "m-filters-container__filter__item-small__item"
              : "m-filters-container__filter__item-large__item",
          ])}
        >
          <MuiSelect
            label=""
            placeholder="Filter by major"
            value={
              majorOptions
                ? majorOptions.find(
                    (option) => option.value === selectedNetworkFilters?.major
                  )
                : ""
            }
            options={majorOptions}
            onChange={(item) =>
              onChangeFilter("major", item ? item.value : null)
            }
            justifyContent
            isClearable={true}
          />
        </div>
      )}
      {userTypeOptions?.length > 0 && (
        <div
          className={cx([
            filtersOpen
              ? "m-filters-container__filter__item-small__item"
              : "m-filters-container__filter__item-large__item",
          ])}
        >
          <MuiSelect
            label=""
            placeholder="Filter by user"
            value={
              userTypeOptions
                ? userTypeOptions.find(
                    (option) =>
                      option.value === selectedNetworkFilters?.user_type
                  )
                : ""
            }
            options={userTypeOptions}
            onChange={(item) =>
              onChangeFilter("user_type", item ? item.value : null)
            }
            justifyContent
            isClearable={true}
          />
        </div>
      )}
      {Object.keys(selectedNetworkFilters).length > 0 && (
        <div
          className={cx("m-filters-container__filter__clear")}
          onClick={onClearFilter}
        >
          <span>Clear all</span>
        </div>
      )}
    </div>
  );

  const ChatInfoPopover = ({ margin }) => (
    <InfoIconPopup
      defaultShow={chatInfoStatus !== true}
      onDefaultShow={() => dispatch(setChatInfoStatus(true))}
      popoverContent={
        <>
          <p>
            For questions related to admissions, applications, and financial
            aid, please contact an admissions staff member.
          </p>
          <p>
            For questions regarding campus life, academics, and the student
            experience, please reach out to one of our student ambassadors
            below.
          </p>
        </>
      }
    />
  );

  return (
    <>
      <div className={cx("m-filters-container")}>
        <div className={cx("m-filters-container__filter")}>
          <div className={cx("m-filters-container__filter__heading")}>
            <h2>
              Chat
              <ChatInfoPopover />
            </h2>
            <div
              className={cx("m-filters-container__filter__heading__tab-view")}
            >
              <Switch
                activeSide={tab}
                setActiveSide={setTab}
                leftTitle="Map view"
                rightTitle="List view"
              />
            </div>
          </div>
          {filterElements}
        </div>
      </div>
      <div className={cx("m-filters-container__small")}>
        <div className={cx("m-filters-container__small__heading")}>
          <h2>Chat</h2>
          <ChatInfoPopover margin={"2px 0px 0px 5px"} />
        </div>

        <div className={cx("m-filters-container__small__heading__tab-view")}>
          <Switch
            activeSide={tab}
            setActiveSide={setTab}
            leftTitle="Map view"
            rightTitle="List view"
          />
        </div>

        <div className={cx("m-filters-container__small__filters")}>
          <div className={cx("m-filters-container__small__filters__card")}>
            <div
              className={cx(
                "m-filters-container__small__filters__card__heading"
              )}
            >
              <h2>Filters</h2>
              <div
                className={cx(
                  "m-filters-container__small__filters__card__heading__buttons"
                )}
              >
                <div
                  className={cx(
                    "m-filters-container__small__filters__card__heading__buttons__filters"
                  )}
                  onClick={() => setFiltersOpen((current) => !current)}
                >
                  <img src={FilterIcon} alt="filter" />
                </div>
              </div>
            </div>
            <div
              className={cx([
                "m-filters-container__small__filters__card__content",
                !filtersOpen
                  ? "m-filters-container__small__filters__card__content--closed"
                  : "",
              ])}
            >
              {filtersOpen && filterElements}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default memo(DiscoverNetworkFilters);
