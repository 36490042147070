import React, { useState, useEffect, memo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";

import { createModuleStyleExtractor } from "../../../utils/css";
import { Button } from "../../atoms/Button/Button";
import styles from "./ProspectSignInForm.module.scss";
import { Modal } from "../../atoms/Modal/Modal";
import { ModalBody } from "../../atoms/Modal/ModalBody/ModalBody";
import { ModalFooter } from "../../atoms/Modal/ModalFooter/ModalFooter";
import FileUpload from "../../atoms/FileUpload/FileUpload";
import PhoneNumberSelect from "../../atoms/PhoneNumberSelect/PhoneNumberSelect";
import Checkbox from "../../atoms/Checkbox/Checkbox";
import {
  checkValidString,
  validatePhoneNumber,
  validateString,
  validateFile,
} from "../../../utils/validations";
import {
  getDropDownOptions,
  updateOnboardigUserProfile,
} from "../../../api/onboarding";
import {
  setUserData as updateUserData,
  removeUserProfileImage,
} from "../../../redux/reducers/userReducer";
import {
  convertObjectToFormData,
  getExpEnrollmentYearOptions,
  setMSClarityTagsForUserProfileData,
} from "../../../utils/common";
import toastify from "../../../utils/toast";
import MuiTextbox, {
  MuiTextboxModifier,
} from "../../atoms/MuiTextbox/MuiTextbox";
import MuiSelect, { MuiSelectModifier } from "../../atoms/MuiSelect/MuiSelect";
import { getSelectOptions } from "../../../utils/noop";
import { updateProfileImage } from "../../../api/profile";

const cx = createModuleStyleExtractor(styles);

const ProspectSignInForm = ({ showModal = false, onSubmit }) => {
  // Loader States
  const [isUpdatingProfileImage, setIsUpdatingProfileImage] = useState(false);

  let formRef = useRef();
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.userState);
  const { user = {}, permissions, selectedSchool } = userState;
  const { educations = [] } = user;

  // Onboarding field setting
  const onboardingSettings = permissions?.custom_onboarding_field;
  const profilePictureSetting = onboardingSettings?.profile_pitcure;
  const nationalitySetting = onboardingSettings?.country_of_origin;
  const degreeSetting = onboardingSettings?.degree;
  const majorSetting = onboardingSettings?.major;
  const enrollmentYearSetting = onboardingSettings?.graduation_year;
  const phoneNoSetting = onboardingSettings?.phone_number;

  const [userData, setUserData] = useState({});
  const [dropDownOptions, setDropDownOptions] = useState({
    nationalities: [],
    degrees: [],
    majors: [],
    graduation_years: [],
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleUserDataChange = (key, value) => {
    setUserData({
      ...userData,
      [key]: value,
    });
  };

  useEffect(() => {
    if (formRef.current) formRef.current.validateForm();
  }, [userData]);

  useEffect(() => {
    const getOptions = async () => {
      const school_id = selectedSchool?.id;
      const response = await getDropDownOptions({
        school_id,
        role: "prospect",
        data_filters: {
          type: [
            { key: "nationalities" },
            { key: "degrees" },
            { key: "majors" },
          ],
        },
      });

      if (response.success && response.data) {
        setDropDownOptions({
          nationalities: getSelectOptions(response.data.nationalities),
          degrees: getSelectOptions(response.data.degrees),
          majors: getSelectOptions(response.data.majors),
          graduation_years: getExpEnrollmentYearOptions(),
        });
      } else {
        toastify(response.message);
      }
    };

    getOptions();

    setUserData({
      first_name: checkValidString(user.first_name) ? user.first_name : "",
      last_name: checkValidString(user.last_name) ? user.last_name : "",
      nationality: checkValidString(user.nationality) ? user.nationality : "",
      degree: checkValidString(educations?.[0]?.degree)
        ? educations[0].degree
        : "",
      major: checkValidString(educations?.[0]?.major)
        ? educations[0].major
        : "",
      graduation_year: user?.graduation_year ? user?.graduation_year : "",
      user_profile: user.profile_image_url ?? "",
      country_code: user.country_code ? user.country_code.replace("+", "") : "",
      phone_number: user.phone_number
        ? (user.country_code ? user.country_code.replace("+", "") : "") +
          user.phone_number
        : "",
      receive_whatsapp_notifications: true,
      sms_opt_in: true,
    });
    setMSClarityTagsForUserProfileData(user);
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    let _userData = { ...userData };

    // Do not send profile if it is a string url. Only send file to BE
    if (typeof _userData.user_profile === "string") {
      delete _userData.user_profile;
    }

    // Do not send if no phone number is provided for country
    if (validatePhoneNumber(userData.phone_number, userData.country_code)) {
      delete _userData.phone_number;
    } else {
      _userData.phone_number = _userData.phone_number.replace(
        _userData.country_code,
        ""
      );
    }

    const response = await updateOnboardigUserProfile(
      user.id,
      convertObjectToFormData("user", _userData)
    );

    if (response.success && response.data) {
      const { users = {} } = response.data;
      dispatch(updateUserData(users));
      toastify(response.message);
      onSubmit(users);
    } else {
      setIsSubmitting(false);
      toastify(response.message);
    }
  };

  const handleProfileImageRemove = async (status) => {
    if (status) {
      setIsUpdatingProfileImage(true);

      let formData = new FormData();
      formData.append("remove_user_profile", true);

      const response = await updateProfileImage(formData);
      if (response?.success) {
        handleUserDataChange("user_profile", ""); // Update form state
        setIsUpdatingProfileImage(false); // Disable loader
        toastify(response.message ?? "Profile image removed"); // Show toast message
        dispatch(removeUserProfileImage()); // Update user redux state
      } else {
        setIsUpdatingProfileImage(false);
        toastify("Oops! something went wrong. Please try again");
      }
    }
  };

  const handlePhoneNumberChange = (phone_number, country_code) => {
    setUserData({
      ...userData,
      phone_number,
      country_code,
    });
  };

  return (
    <div className={cx("m-prospect-sign-in-form-container")}>
      <Modal show={showModal} hideClose centerAlign={false}>
        <Formik
          innerRef={formRef}
          initialValues={{
            first_name: "",
            last_name: "",
            nationality: "",
            degree: "",
            major: "",
            graduation_year: "",
            user_profile: "",
            phone_number: "",
            receive_whatsapp_notifications: true,
            sms_opt_in: true,
          }}
          validate={() => {
            let errors = {};
            if (
              profilePictureSetting?.is_visible &&
              profilePictureSetting?.is_mendatory &&
              validateFile(userData.user_profile)
            ) {
              errors.user_profile = "Profile picture is required";
            }
            if (validateString(userData.first_name)) {
              errors.first_name = "First name is required";
            } else if (
              !/^([a-zA-Z]+\s)*[a-zA-Z]+$/i.test(userData.first_name)
            ) {
              errors.first_name = "First name is not valid";
            }
            if (validateString(userData.last_name)) {
              errors.last_name = "Last name is required";
            } else if (!/^([a-zA-Z]+\s)*[a-zA-Z]+$/i.test(userData.last_name)) {
              errors.last_name = "Last name is not valid";
            }
            if (
              nationalitySetting?.is_visible &&
              nationalitySetting?.is_mendatory &&
              validateString(userData.nationality)
            ) {
              errors.nationality = "Nationality is required";
            }
            if (
              degreeSetting?.is_visible &&
              degreeSetting?.is_mendatory &&
              validateString(userData.degree)
            ) {
              errors.degree = "Degree is required";
            }
            if (
              majorSetting?.is_visible &&
              majorSetting?.is_mendatory &&
              validateString(userData.major)
            ) {
              errors.major = "Major is required";
            }
            if (
              enrollmentYearSetting?.is_visible &&
              enrollmentYearSetting?.is_mendatory &&
              validateString(userData.graduation_year)
            ) {
              errors.graduation_year = "Expected enrollment year is required";
            }
            if (
              ((phoneNoSetting?.is_visible && phoneNoSetting?.is_mendatory) ||
                (phoneNoSetting?.is_visible &&
                  phoneNoSetting?.is_mendatory &&
                  (userData.sms_opt_in ||
                    userData.receive_whatsapp_notifications))) &&
              validatePhoneNumber(userData.phone_number, userData.country_code)
            ) {
              errors.phone_number = phoneNoSetting?.is_mendatory
                ? "Phone number is required"
                : "Phone number is required for receiving marketing messages or whatsapp notifications";
            }

            // if (userData.introduction === "") {
            //   errors.introduction = "Introduction is required";
            // }
            return errors;
          }}
          onSubmit={() => {
            handleSubmit();
          }}
        >
          {({ errors, touched, isValidating, handleBlur }) => (
            <Form>
              <ModalBody>
                <h2>Finalize your profile to get started.</h2>

                <div
                  className={cx(
                    "m-prospect-sign-in-form-container__formfields"
                  )}
                >
                  {profilePictureSetting?.is_visible && (
                    <Field name="user_profile">
                      {({ field, meta }) => (
                        <div
                          className={cx(
                            "m-prospect-sign-in-form-container__formfields__user-profile"
                          )}
                        >
                          <label>
                            Upload a profile picture{" "}
                            {profilePictureSetting?.is_mendatory
                              ? "*"
                              : "(optional)"}
                          </label>
                          <FileUpload
                            {...field}
                            name={"user_profile"}
                            userName={user.name}
                            maxFileSize={5}
                            defaultBackgroundImage={userData.user_profile}
                            onSelectSuccess={(file) =>
                              handleUserDataChange("user_profile", file)
                            }
                            onRemoveSuccess={(status) =>
                              handleProfileImageRemove(status)
                            }
                            loader={isUpdatingProfileImage}
                            cropperAlign="start"
                          />
                          {meta.touched && meta.error && (
                            <span
                              className="p-error"
                              style={{ marginTop: "6px" }}
                            >
                              {meta.error}
                            </span>
                          )}
                        </div>
                      )}
                    </Field>
                  )}

                  <Field name="first_name">
                    {({ field, meta }) => (
                      <div
                        className={cx(
                          "m-prospect-sign-in-form-container__formfields--group"
                        )}
                      >
                        <MuiTextbox
                          {...field}
                          value={userData.first_name}
                          onChange={(e) =>
                            handleUserDataChange("first_name", e.target.value)
                          }
                          name={"first_name"}
                          label="Your first name*"
                          placeholder={"Enter first name"}
                          error={meta.touched && meta.error}
                          modifier={MuiTextboxModifier.CENTER_ALIGN}
                        />
                      </div>
                    )}
                  </Field>
                  <Field name="last_name">
                    {({ field, meta }) => (
                      <div
                        className={cx(
                          "m-prospect-sign-in-form-container__formfields--group"
                        )}
                      >
                        <MuiTextbox
                          {...field}
                          value={userData.last_name}
                          onChange={(e) =>
                            handleUserDataChange("last_name", e.target.value)
                          }
                          name={"last_name"}
                          label="Your last name*"
                          placeholder={"Enter last name"}
                          error={meta.touched && meta.error}
                          modifier={MuiTextboxModifier.CENTER_ALIGN}
                        />
                      </div>
                    )}
                  </Field>
                  {nationalitySetting?.is_visible && (
                    <Field name="nationality">
                      {({ field, meta, form: { setFieldTouched } }) => (
                        <div
                          className={cx(
                            "m-prospect-sign-in-form-container__formfields--group"
                          )}
                        >
                          <MuiSelect
                            {...field}
                            label={`Your nationality${
                              nationalitySetting?.is_mendatory ? "*" : ""
                            }`}
                            placeholder={"Select a country"}
                            name={"nationality"}
                            value={
                              dropDownOptions.nationalities
                                ? dropDownOptions.nationalities.find(
                                    (option) =>
                                      option.value === userData.nationality
                                  )
                                : ""
                            }
                            options={dropDownOptions.nationalities}
                            onChange={(e) =>
                              handleUserDataChange("nationality", e.value)
                            }
                            onBlur={(e) => setFieldTouched("nationality", true)}
                            error={meta.touched && meta.error}
                          />
                        </div>
                      )}
                    </Field>
                  )}

                  {degreeSetting?.is_visible && (
                    <Field name="degree">
                      {({ field, meta, form: { setFieldTouched } }) => (
                        <div
                          className={cx(
                            "m-prospect-sign-in-form-container__formfields--group"
                          )}
                        >
                          <MuiSelect
                            {...field}
                            label={`Desired degree${
                              degreeSetting?.is_mendatory ? "*" : ""
                            }`}
                            placeholder={"Select a degree"}
                            name={"degree"}
                            value={
                              dropDownOptions.degrees
                                ? dropDownOptions.degrees.find(
                                    (option) => option.value === userData.degree
                                  )
                                : ""
                            }
                            options={dropDownOptions.degrees}
                            onChange={(e) =>
                              handleUserDataChange("degree", e.value)
                            }
                            onBlur={(e) => setFieldTouched("degree", true)}
                            error={meta.touched && meta.error}
                          />
                        </div>
                      )}
                    </Field>
                  )}
                  {majorSetting?.is_visible && (
                    <Field name="major">
                      {({ field, meta, form: { setFieldTouched } }) => (
                        <div
                          className={cx(
                            "m-prospect-sign-in-form-container__formfields--group"
                          )}
                        >
                          <MuiSelect
                            {...field}
                            label={`Desired major${
                              majorSetting?.is_mendatory ? "*" : ""
                            }`}
                            placeholder={"Enter major"}
                            name={"major"}
                            value={
                              dropDownOptions.majors === null
                                ? ""
                                : dropDownOptions.majors?.includes(
                                    (option) => option.value === userData.major
                                  )
                                ? dropDownOptions.majors.find(
                                    (option) => option.value === userData.major
                                  )
                                : {
                                    label: userData.major,
                                    value: userData.major,
                                  }
                            }
                            options={dropDownOptions.majors}
                            onChange={(e) =>
                              handleUserDataChange("major", e.value)
                            }
                            onBlur={(e) => setFieldTouched("major", true)}
                            error={meta.touched && meta.error}
                            modifier={MuiSelectModifier.LEFT_ALIGN}
                            addText="Add major"
                            editable={true}
                          />
                        </div>
                      )}
                    </Field>
                  )}
                  {enrollmentYearSetting?.is_visible && (
                    <Field name="graduation_year">
                      {({ field, meta, form: { setFieldTouched } }) => (
                        <div
                          className={cx(
                            "m-prospect-sign-in-form-container__formfields--group"
                          )}
                        >
                          <MuiSelect
                            {...field}
                            name={"graduation_year"}
                            label={`Expected enrollment year${
                              enrollmentYearSetting?.is_mendatory ? "*" : ""
                            }`}
                            placeholder={"Select a year"}
                            value={
                              dropDownOptions.graduation_years
                                ? dropDownOptions.graduation_years.find(
                                    (option) =>
                                      option.value === userData.graduation_year
                                  )
                                : ""
                            }
                            options={dropDownOptions.graduation_years}
                            onChange={(e) =>
                              handleUserDataChange("graduation_year", e.value)
                            }
                            onBlur={(e) =>
                              setFieldTouched("graduation_year", true)
                            }
                            error={meta.touched && meta.error}
                          />
                        </div>
                      )}
                    </Field>
                  )}

                  {phoneNoSetting?.is_visible && (
                    <>
                      <Field name="phone_number">
                        {({ field, meta, form: { setFieldTouched } }) => (
                          <div
                            className={cx(
                              "m-prospect-sign-in-form-container__formfields--group"
                            )}
                          >
                            <PhoneNumberSelect
                              {...field}
                              name={"phone_number"}
                              label={`Your phone number${
                                phoneNoSetting?.is_mendatory ? "*" : ""
                              }`}
                              placeholder={"Enter phone number"}
                              value={userData.phone_number}
                              onChange={handlePhoneNumberChange}
                              onBlur={() =>
                                setFieldTouched("phone_number", true)
                              }
                              error={meta.touched && meta.error}
                            />
                          </div>
                        )}
                      </Field>
                      <div
                        className={cx(
                          "m-prospect-sign-in-form-container__formfields--checkbox_container"
                        )}
                      >
                        <div
                          className={cx(
                            "m-prospect-sign-in-form-container__formfields--checkbox"
                          )}
                        >
                          <Checkbox
                            value={userData.receive_whatsapp_notifications}
                            onChange={(e) => {
                              handleUserDataChange(
                                "receive_whatsapp_notifications",
                                !e.target.checked
                              );
                            }}
                            name={"receive_whatsapp_notifications"}
                            label={"Send me notifications through WhatsApp."}
                          />
                        </div>

                        <div
                          className={cx(
                            "m-prospect-sign-in-form-container__formfields--checkbox"
                          )}
                        >
                          <Checkbox
                            value={userData.sms_opt_in}
                            onChange={(e) => {
                              handleUserDataChange(
                                "sms_opt_in",
                                !e.target.checked
                              );
                            }}
                            name={"sms_opt_in"}
                            label={
                              "I would like to receive marketing messages."
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </ModalBody>
              <ModalFooter
                className={cx("m-prospect-sign-in-form-container__footer")}
              >
                <Button
                  type="submit"
                  isSubmitting={isSubmitting}
                  disabled={
                    Object.keys(formRef?.current?.errors || {})?.length > 0
                  }
                >
                  Continue
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};
export default memo(ProspectSignInForm);
