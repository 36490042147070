import React, { memo, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import { TextareaAutosize } from "@mui/material";

import styles from "./PostComment.module.scss";
import { createModuleStyleExtractor } from "../../../utils/css";
import { CircularAvatar } from "../../atoms/CircularAvatar/CircularAvatar";
import { Button, ButtonModifier } from "../../atoms/Button/Button";
import { createOrUpdatePostComments } from "../../../api/topics";
import { validateString } from "../../../utils/validations";
import toastify from "../../../utils/toast";

import BinDelete from "../../../assets/Icons/bin-delete.svg";
import LinkContent from "../../atoms/LinkContent/LinkContent";

const cx = createModuleStyleExtractor(styles);

const SHOW_LESS_COMMENTS_NUMBER = 5;
const SHOW_MORE_COMMENTS_NUMBER = 25;
const MAX_COMMENT_LENGTH = 500;

const PostComment = ({
  post = {},
  setTopicDetails,
  setTopicPostCommentDeleteId,
  setDeletePostCommentModal,
}) => {
  const {
    user = {},
    permissions = {},
    isProspect,
    isParent,
  } = useSelector((store) => store.userState);
  const [comment, setComment] = useState("");
  const [isSubmittingComment, setIsSubmittingComment] = useState(false);
  const [commentListExpanded, setCommentListExpanded] = useState(false);

  const handleCommentSubmit = async () => {
    // Check for empty comment
    if (comment === "") return;

    // Call Submit API
    try {
      setIsSubmittingComment(true);

      const response = await createOrUpdatePostComments(
        post.topic_post.topic_id,
        comment?.trim(),
        post.topic_post.id
      );

      if (response.success) {
        setComment("");
        setIsSubmittingComment(false);
        // Toast: Comment Posted

        // Update Topic Data with comment without API call
        setTopicDetails((current) => {
          let _current = current;

          // Find index of topic post
          const index_topic_post = _current.topic_posts_details.findIndex(
            (element) => element.topic_post.id === post.topic_post.id
          );

          _current.topic_posts_details[
            index_topic_post
          ].topic_post_comments.push(response.data.topic);

          return {
            ...current,
          };
        });
      } else {
        setIsSubmittingComment(false);
        toastify(response.message);
      }
    } catch (error) {
      console.error(error);
      setIsSubmittingComment(false);
    }
  };

  const isWidgetUser = isProspect || isParent;

  // Comment permission is only applicable to prospect/parent role
  const allowAddComment =
    !isWidgetUser || (isWidgetUser && permissions["topic_comments"]);

  return (
    <>
      {(allowAddComment || post.topic_post_comments.length > 0) && (
        <hr className={cx("m-my-topic-middle-post-detail-hr")} />
      )}

      {post.topic_post_comments.length > 0 && (
        <>
          {post.topic_post_comments
            .slice(
              0,
              commentListExpanded
                ? SHOW_MORE_COMMENTS_NUMBER
                : SHOW_LESS_COMMENTS_NUMBER
            )
            .map((comment, index) => (
              <div key={index} className={cx("m-topic-comment-wrapper")}>
                <CircularAvatar
                  alt="profile"
                  round
                  name={comment.commneted_by_name}
                  src={comment?.commneted_by_image ?? null}
                  size={40}
                />

                <div className={cx("m-topic-comment-details")}>
                  <div>
                    <span>{comment.commneted_by_name}</span>
                    <span>{moment(comment.commented_at).calendar()}</span>
                  </div>
                  <div className={cx("m-topic-comment-details__comment")}>
                    <p>
                      <LinkContent>{comment.comment}</LinkContent>
                    </p>
                  </div>
                </div>
                {comment.commneted_by_id === user.id && (
                  <div
                    className={cx("m-topic-comment-wrapper__delete")}
                    onClick={() => {
                      setTopicPostCommentDeleteId({
                        id: comment.id,
                        topicPostId: comment.topic_post_id,
                      });
                      setDeletePostCommentModal(true);
                    }}
                  >
                    <img src={BinDelete} alt="del" onClick={() => {}} />
                  </div>
                )}
              </div>
            ))}
          {post.topic_post_comments.length > SHOW_LESS_COMMENTS_NUMBER && (
            <div className={cx("m-topic-comment-expand-button-wrapper")}>
              <Button
                modifier={ButtonModifier.TOPICS_PARTICIPANT_LIST_VIEW}
                onClick={() => setCommentListExpanded((current) => !current)}
              >
                {commentListExpanded ? "Less" : "View more"}
              </Button>
            </div>
          )}
        </>
      )}

      {allowAddComment && (
        <div className={cx("m-topic-user-comment")}>
          <div className={cx("m-topic-user-comment__avatar")}>
            <CircularAvatar
              src={user.profile_image_url}
              alt="profile"
              round
              name={`${user.first_name} ${user.last_name}`}
              size={35}
            />
          </div>
          <div className={cx("m-topic-user-comment__textbox")}>
            <TextareaAutosize
              variant="standard"
              multiline
              minRows={0}
              maxRows={3}
              placeholder="Leave your comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              maxLength={MAX_COMMENT_LENGTH}
            />
          </div>
          <div className={cx("m-topic-user-comment__button")}>
            <Button
              modifier={ButtonModifier.NOTIFICATIONS_DRAWER_VIEW}
              disabled={isSubmittingComment || validateString(comment)}
              onClick={handleCommentSubmit}
            >
              {isSubmittingComment ? (
                <ThreeDots color="#ffffff" height={10} width={25} />
              ) : (
                <>Post</>
              )}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(PostComment);
