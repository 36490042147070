import React from "react";

export default function CampusMapRestaurantIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="43"
      viewBox="0 0 36 43"
      fill="none"
    >
      <path
        d="M36 17.623C36 27.356 18 43 18 43S0 27.356 0 17.623 8.059 0 18 0s18 7.89 18 17.623"
        fill="var(--primary-color)"
      />
      <path
        d="M11.353 25.895 23.73 12.976m1.375 12.919L11.181 10.423a.164.164 0 0 0-.286.11v0c0 2.823.946 5.565 2.688 7.787l1.437 1.833m6.877-9.09-1.833 2.185a2.39 2.39 0 0 0 .106 3.19v0a2.39 2.39 0 0 0 3.453 0l1.482-1.548"
        stroke="#fff"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
