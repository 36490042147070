import React, { memo } from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./NSwitch.module.scss";

const cx = createModuleStyleExtractor(styles);

// Switch component with varying options
const NSwitch = ({
  options = [], // Example: [{key:"resources", value: "Resources"}, ...]
  activeSide, // Controlling state getter
  setActiveSide = () => {}, // Controlling state setter
}) => {
  return (
    <div className={cx("a-n-switch")}>
      {options?.map((option) => (
        <div
          key={option?.key}
          onClick={() => {
            setActiveSide(option);
          }}
          className={cx([
            "a-n-switch-item",
            activeSide.key === option.key ? "a-n-switch-item--active" : "",
          ])}
        >
          {option?.value}
        </div>
      ))}
    </div>
  );
};

export default memo(NSwitch);
