import React, { memo, useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import { createModuleStyleExtractor } from "../../utils/css";
import Header from "../../components/molecules/Header/Header";
import styles from "./MainLayout.module.scss";
import ProspectSignInForm from "../../components/molecules/ProspectSignInForm/ProspectSignInForm";
import ParentSignInForm from "../../components/molecules//ParentSignInForm/ParentSignInForm";
import AdmbassadorsSignInForm from "../../components/molecules/AdmbassadorsSignInForm/AdmbassadorsSignInForm";
import {
  checkCompleteAmbassadorProfile,
  checkCompleteParentProfile,
  checkCompleteProspectProfile,
  isNull,
} from "../../utils/validations";
import { ROUTES } from "../../routes";
import {
  getNewToken,
  getUserPermissions,
  getFeedbackStatus,
} from "../../api/onboarding";
import {
  setTokenData,
  updateUserPermissions,
} from "../../redux/reducers/userReducer";
import { Mixpanel } from "../../MixPanel/mixpanel";
import ParentProspectInviteForm from "../../components/molecules/ParentProspectInviteForm/ParentProspectInviteForm";
import LeaveFeedbackModal from "../../components/molecules/LeaveFeedbackModal/LeaveFeedbackModal";

const cx = createModuleStyleExtractor(styles);

const MainLayout = ({ children, className = "" }) => {
  const navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();

  const userState = useSelector((state) => state.userState);
  const isProspect = userState.isProspect;
  const isParent = userState.isParent;
  const isStudentAmbassador = userState.isStudentAmbassador;
  const isAlumniAmbassador = userState.isAlumniAmbassador;
  const isSchoolAdmin = userState.isSchoolAdmin;
  const isSuperAdmin = userState.isSuperAdmin;

  const [selectedSubMenu, setSelectedSubMenu] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  const { user = {} } = userState;
  const { educations = [] } = user;

  useEffect(() => {
    const fetchFeedbackStatus = async () => {
      if (userState?.selectedSchool?.id) {
        const response = await getFeedbackStatus(user?.id);
        if (response.success && response.data) {
          const { boost_profile = {} } = response.data;
          const feedbackStatus = boost_profile?.rate_interstride;
          if (user?.sign_in_count && user?.sign_in_count > 3) {
            const feedbackDialogTimestamp = localStorage.getItem(
              "feedback_dialog_timestamp"
            );
            if (
              feedbackStatus === false &&
              (!feedbackDialogTimestamp ||
                moment().isAfter(moment(feedbackDialogTimestamp)))
            )
              setShowFeedbackModal(true);
            else setShowFeedbackModal(false);
          }
        }
      } else {
        dispatch(updateUserPermissions({}));
      }
    };

    if (!userState.isLogin) {
      navigate(ROUTES.LOGIN_WITH_PARAMS, { replace: true });
    } else {
      fetchFeedbackStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.isLogin, navigate]);

  useEffect(() => {
    if (isProspect) {
      const completeProfile = checkCompleteProspectProfile({
        ...user,
        ...(educations && educations[0] ? educations[0] : {}),
      });
      if (!completeProfile) setShowSignInModal("Prospect");
      else setMixPanelUserData();
    }
    if (isParent) {
      const completeProfile = checkCompleteParentProfile(user);
      if (!completeProfile) setShowSignInModal("Parent");
      else setMixPanelUserData();
    }
    if (isStudentAmbassador || isAlumniAmbassador) {
      const completeProfile = checkCompleteAmbassadorProfile({
        ...user,
        ...(educations && educations[0] ? educations[0] : {}),
      });
      if (!completeProfile) setShowSignInModal("Ambassador");
      else setMixPanelUserData();
    }
    // eslint-disable-next-line
  }, []);

  // Check if profile is completed, on permissions load, and open appropriate profile popup
  useEffect(() => {
    // Check if premissions are available
    if (Object.keys(userState?.permissions).length === 0) return;

    if (isProspect) {
      const completeProfile = checkCompleteProspectProfile(
        {
          ...user,
          ...(educations && educations[0] ? educations[0] : {}),
        },
        userState?.permissions?.custom_onboarding_field
      );
      if (!completeProfile) setShowSignInModal("Prospect");
      else setMixPanelUserData();
    }
    if (isParent) {
      const completeProfile = checkCompleteParentProfile(user);
      if (!completeProfile) setShowSignInModal("Parent");
      else setMixPanelUserData();
    }
    if (isStudentAmbassador || isAlumniAmbassador) {
      const completeProfile = checkCompleteAmbassadorProfile({
        ...user,
        ...(educations && educations[0] ? educations[0] : {}),
      });
      if (!completeProfile) setShowSignInModal("Ambassador");
      else setMixPanelUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userState?.permissions, // Important dependency as permissions are loaded afterwards
  ]);

  const setMixPanelUserData = (mixpanelUser = user) => {
    const { educations = [] } = mixpanelUser;
    Mixpanel.identify(mixpanelUser.id);
    Mixpanel.people.set({
      $user_Id: mixpanelUser.id ? mixpanelUser.id : "",
      $user_type: isProspect
        ? "Prospect"
        : isParent
        ? "Parent"
        : isStudentAmbassador
        ? "Student Ambassador"
        : isAlumniAmbassador
        ? "Alumni Ambassador"
        : isSchoolAdmin
        ? "School Admin"
        : isSuperAdmin
        ? "Super Admin"
        : "Other",
      $account_type: mixpanelUser.account_type
        ? mixpanelUser.account_type
        : "Not Defined",
      $name: mixpanelUser.name ? mixpanelUser.name : "Not Defined",
      $email: mixpanelUser.email ? mixpanelUser.email : "Not Defined",
      $first_name: mixpanelUser.first_name
        ? mixpanelUser.first_name
        : "Not Defined",
      $last_name: mixpanelUser.last_name
        ? mixpanelUser.last_name
        : "Not Defined",
      $school_name: mixpanelUser.school_name
        ? mixpanelUser.school_name
        : "Not Defined",
      $major:
        educations && educations[0] && educations[0].major
          ? educations[0].major
          : "Not Defined",
      $degree:
        educations && educations[0] && educations[0].degree
          ? educations[0].degree
          : "Not Defined",
      $graduation_year:
        educations && educations[0] && educations[0].graduation_year
          ? educations[0].graduation_year
          : "Not Defined",
      $nationality: mixpanelUser.nationality
        ? mixpanelUser.nationality
        : "Not Defined",
      $phone_number: mixpanelUser.phone_number
        ? mixpanelUser.country_code
          ? `${mixpanelUser.country_code} ${mixpanelUser.phone_number}`
          : mixpanelUser.phone_number
        : "Not Defined",
    });
  };

  const tokenExpiryRef = useRef(userState.tokenExpiry);
  useEffect(() => {
    tokenExpiryRef.current = userState.tokenExpiry;
  });

  useEffect(() => {
    const refreshToken = async () => {
      // console.log("Refresh token!");
      const response = await getNewToken(userState.keepSignedIn);

      if (response.success && response.data) {
        // console.log("Saving refresh token!");
        dispatch(setTokenData(response.data));
      } else {
        // Redirect to login if refresh token fails
        // console.log("Redirecting to login");
        navigate(ROUTES.LOGIN_WITH_PARAMS);
      }
    };

    // console.log("Location changed!");
    if (!isNull(userState.tokenExpiry)) {
      // console.log("Checking token expiry!");

      let tokenExpiryCheck;
      if (userState.keepSignedIn) {
        // Refresh token within 24 hours of expiry when keepSignedIn is on
        tokenExpiryCheck = moment().add(24, "hours").unix();
      } else {
        // Refresh token within 5 minutes of expiry when keepSignedIn is off
        tokenExpiryCheck = moment().add(5, "minutes").unix();
      }

      if (tokenExpiryCheck >= moment(tokenExpiryRef.current).unix()) {
        refreshToken();
      }
    }

    // Fetch user's school permissions
    const fetchUserPermissions = async () => {
      if (userState?.selectedSchool?.id) {
        const response = await getUserPermissions(
          userState?.selectedSchool?.id
        );
        if (response.success && response.data) {
          dispatch(updateUserPermissions(response.data));
        }
      } else {
        dispatch(updateUserPermissions({}));
      }
    };

    if (
      userState?.isLogin &&
      (userState?.permissionsExpiry === null ||
        moment().isAfter(moment(userState?.permissionsExpiry)))
    )
      fetchUserPermissions();

    window.scrollTo({ top: 0, behavior: "smooth" });
    // eslint-disable-next-line
  }, [location, navigate, dispatch]);

  const handleParentProspectSubmit = (submittedData) => {
    setMixPanelUserData(submittedData);
    setShowSignInModal(false);
    navigateToProfileWithParam();
    if (user.can_invite) setShowInviteModal(true);
  };

  // If user is on profile page when the modal closes, we need to recall user_information api
  // to get latest data. To achive this, we will append param in profile route and profile
  // component will handle the api call
  const navigateToProfileWithParam = () => {
    if (window?.location?.pathname?.includes(ROUTES?.PROFILE)) {
      navigate(ROUTES.PROFILE + "?reload=true");
    }
  };

  return (
    <div className={cx("main-layout-container")}>
      <Header
        selectedMenu={selectedMenu}
        setSelectedMenu={setSelectedMenu}
        selectedSubMenu={selectedSubMenu}
        setSelectedSubMenu={setSelectedSubMenu}
        user={userState.user}
      />

      <div className={cx(["main-layout-container__content", className])}>
        {children}
      </div>

      {showSignInModal && showSignInModal === "Prospect" && (
        <ProspectSignInForm
          showModal={showSignInModal === "Prospect"}
          closeModal={() => setShowSignInModal(false)}
          onSubmit={handleParentProspectSubmit}
        />
      )}

      {showSignInModal && showSignInModal === "Parent" && (
        <ParentSignInForm
          showModal={showSignInModal === "Parent"}
          closeModal={() => setShowSignInModal(false)}
          onSubmit={handleParentProspectSubmit}
        />
      )}

      {showSignInModal && showSignInModal === "Ambassador" && (
        <AdmbassadorsSignInForm
          showModal={showSignInModal === "Ambassador"}
          closeModal={(submittedData) => {
            setMixPanelUserData(submittedData);
            navigateToProfileWithParam();
            setShowSignInModal(false);
          }}
        />
      )}

      {showInviteModal && (
        <ParentProspectInviteForm
          showModal={showInviteModal}
          closeModal={() => setShowInviteModal(false)}
          role={isProspect ? "parent" : "prospect"}
        />
      )}

      {showFeedbackModal && (
        <LeaveFeedbackModal setShowModal={setShowFeedbackModal} />
      )}
    </div>
  );
};
export default memo(MainLayout);
