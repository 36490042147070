import React, { memo } from "react";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./Card.module.scss";

const cx = createModuleStyleExtractor(styles);

export const CardModifier = {
  RESOURCES: "a-card--resources",
  NOTIFICATIONS_DRAWER: "a-card--notifications-drawer",
  NOTIFICATION: "a-card--notification",
  CREATE_TOPIC_POST: "a-card--create-topic-post",
  LISTING_EFFECT: "a-card--listing-effect",
  RESOURCE_LISTING_EFFECT: "a-card--resource-listing-effect",
  STUDENT_SERVICE: "a-card--student-service",
};

const Card = ({ children, modifier, onClick = () => {} }) => {
  return (
    <div
      className={cx("a-card", modifier ? { [modifier]: true } : {})}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default memo(Card);
