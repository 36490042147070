import React from "react";
import Avatar, { Cache, ConfigProvider } from "react-avatar";

import { noop } from "../../../utils/noop";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./CircularAvatar.module.scss";

const cache = new Cache({
  // Keep cached source failures for up to 1 day
  sourceTTL: 1 * 24 * 3600 * 1000,
  // Keep a maximum of 20 entries in the source cache
  sourceSize: 20,
});

const cx = createModuleStyleExtractor(styles);

export const CircularAvatar = ({
  wrapperClass,
  src,
  name,
  alt,
  color,
  fgColor,
  size,
  round,
  maxInitials = 2,
  textSizeRatio,
  initials,
  value,
  onClick,
}) => {
  return (
    <div className={wrapperClass ?? cx("a-circular-avatar")}>
      <ConfigProvider cache={cache}>
        <Avatar
          value={value}
          initials={initials}
          textSizeRatio={textSizeRatio}
          maxInitials={maxInitials}
          name={name}
          src={src}
          alt={alt}
          color={color}
          round={round}
          fgColor={fgColor}
          size={size}
          onClick={onClick ?? noop}
          className={cx("a-circular-avatar__image")}
        />
      </ConfigProvider>
    </div>
  );
};
