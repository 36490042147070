import React, { memo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./NotificationsSettings.module.scss";
import { Col } from "../../helpers/Grid/Col";
import { Row } from "../../helpers/Grid/Row";
import Categories from "../../atoms/Categories/Categories";
import NetworkSettings from "./NetworkSettings/NetworkSettings";
import TopicsSettings from "./TopicsSettings/TopicsSettings";
import WebinarsSettings from "./WebinarsSettings/WebinarsSettings";
import ResourcesSettings from "./ResourcesSettings/ResourcesSettings";
import StudentServicesSettings from "./StudentServicesSettings/StudentServicesSettings";
import HomeFeedSettings from "./HomeFeedSettings/HomeFeedSettings";
import ToggleSwitch from "../../atoms/ToggleSwitch/ToggleSwitch";

import {
  fetchNotificationSettings,
  updateNotificationSettings,
  updateUnsubscribeNotification,
} from "../../../api/notification";
import {
  setAllNotificationSettings,
  setNotificationSettings,
  unsubscribeNotificationUpdate,
} from "../../../redux/reducers/notificationReducer";
import { Mixpanel } from "../../../MixPanel/mixpanel";

import FilterIcon from "../../../assets/Icons/FilterIcon";
import ChatSettingIcon from "../../../assets/svg/notification-settings/Chat.svg";
import ResourcesSettingIcon from "../../../assets/svg/notification-settings/Resources.svg";
import CommunitiesSettingIcon from "../../../assets/svg/notification-settings/Communities.svg";
import WebinarsSettingIcon from "../../../assets/svg/notification-settings/Webinars.svg";

const cx = createModuleStyleExtractor(styles);

const Notifications = [
  "webinars",
  "resources",
  "services",
  "user_posts",
  "job_saved_searches",
  "recommended_jobs",
  "followed_topics",
  "created_topics",
  "new_topics",
  "group_messages",
  "group_invites",
  "network_messages",
];

const NotificationsSettings = () => {
  const dispatch = useDispatch();

  const { userId, permissions = {} } = useSelector((store) => store.userState);

  const notificationState = useSelector((state) => state.notificationState);
  const unsubscribePush = notificationState.unsubscribePush;
  const unsubscribeEmail = notificationState.unsubscribeEmail;

  const NotificationsCategoriesData = [
    ...(permissions["chat"]
      ? [
          {
            value: "networking",
            category_name: "Chat",
            icon: ChatSettingIcon,
          },
        ]
      : []),
    ...(permissions["topics"]
      ? [
          {
            value: "topics",
            category_name: "Communities",
            icon: CommunitiesSettingIcon,
            settingsList: [],
          },
        ]
      : []),
    ...(permissions["webinar"]
      ? [
          {
            value: "webinars",
            category_name: "Webinars",
            icon: WebinarsSettingIcon,
            settingsList: [],
          },
        ]
      : []),
    ...(permissions["resources"]
      ? [
          {
            value: "resources",
            category_name: "Resources",
            icon: ResourcesSettingIcon,
            settingsList: [],
          },
        ]
      : []),
    ...(permissions["student_services"]
      ? [
          {
            value: "student_services",
            category_name: "Services",
            icon: ChatSettingIcon,
            settingsList: [],
          },
        ]
      : []),
    {
      value: "homefeed",
      category_name: "Home Feed",
      icon: ChatSettingIcon,
      settingsList: [],
    },
  ];

  const [selectedCategory, setSelectedCategory] = useState(
    NotificationsCategoriesData[0]
  );
  const [openFilter, toggleFilter] = useState(false);

  const onCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const getNotificationSettings = async () => {
    const response = await fetchNotificationSettings(userId);

    if (response.success) {
      const {
        notification_setting = {},
        notification_setting_data: {
          email_notifications = [],
          push_notifications = [],
        } = {},
      } = response.data;
      dispatch(
        setAllNotificationSettings({
          notificationSettings: notification_setting,
          emailNotifications: email_notifications,
          pushNotifications: push_notifications,
        })
      );
    }
  };

  const toggleNotificationSettings = async (type, value) => {
    const data = {
      notification_setting: {
        email_notifications: [],
        push_notifications: [],
      },
    };

    if (type === "email")
      data.notification_setting.email_notifications.push(value);
    else data.notification_setting.push_notifications.push(value);

    const response = await updateNotificationSettings(data);

    if (response.success) {
      const { notifications = {} } = response.data;
      dispatch(
        setNotificationSettings({
          notificationSettings: notifications,
        })
      );
    }
  };

  // To change notification frequency
  const applyNotificationFrequency = async (
    emailFrequencyKey,
    frequencyValue
  ) => {
    const formData = new FormData();
    formData.append(
      `notification_setting[${emailFrequencyKey}]`,
      frequencyValue
    );
    const response = await updateNotificationSettings(formData);

    if (response.success) {
      const { notifications = {} } = response.data;
      dispatch(
        setNotificationSettings({
          notificationSettings: notifications,
        })
      );
    }
  };

  const toggleUnsubscribeNotificationSettings = async (type) => {
    const data = {};

    if (type === "email") {
      data.is_email_notification = true;
      data.email_notifications = !unsubscribeEmail ? [] : [...Notifications];
    } else {
      data.is_push_notification = true;
      data.push_notifications = !unsubscribePush ? [] : [...Notifications];
    }

    const response = await updateUnsubscribeNotification(data);

    if (response.success) {
      const { notifications = {} } = response.data;
      dispatch(
        unsubscribeNotificationUpdate({
          notificationSettings: notifications,
          unsubscribeEmail:
            type === "email" ? !unsubscribeEmail : unsubscribeEmail,
          unsubscribePush: type === "push" ? !unsubscribePush : unsubscribePush,
        })
      );
    }
  };

  useEffect(() => {
    Mixpanel.track(`Notifications settings visited`);
    getNotificationSettings();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={cx("o-notifications-container")}>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <div className={cx("o-notifications-title")}>
            <h1>Manage notifications</h1>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12}>
          <div className={cx("o-notifications-title-controls")}>
            <div className={cx("o-notifications-title-controls-one")}>
              <span className={cx("o-notifications-title-controls-one-label")}>
                All emails
              </span>
              <ToggleSwitch
                isChecked={unsubscribeEmail}
                setIsChecked={() => {
                  if (unsubscribeEmail)
                    Mixpanel.track("Email notification subscribed");
                  else Mixpanel.track("Email notification unsubscribed");
                  toggleUnsubscribeNotificationSettings("email");
                }}
              />
            </div>
            <div className={cx("o-notifications-title-controls-two")}>
              <span className={cx("o-notifications-title-controls-two-label")}>
                All in app push
              </span>
              <ToggleSwitch
                isChecked={unsubscribePush}
                setIsChecked={() => {
                  if (unsubscribeEmail)
                    Mixpanel.track("Push notification subscribed");
                  else Mixpanel.track("Push notification unsubscribed");
                  toggleUnsubscribeNotificationSettings("push");
                }}
              />
            </div>
          </div>
        </Col>
        <Col xs={3} sm={3} md={3} lg={3}>
          <div
            className={`collapsible-section ${
              openFilter ? "collapsible-section--open" : ""
            }`}
          >
            <div className={cx("o-notifications-categories-wrapper")}>
              <div id={"collapsible-section-header"}>
                <h2
                  className={cx("o-notifications-categories-wrapper__back-btn")}
                >
                  {/* <Link to={ROUTES.NOTIFICATIONS}>
                    <BackIcon />
                    
                  </Link> */}
                  Categories
                </h2>
                <a
                  href="/#"
                  onClick={(e) => {
                    e?.preventDefault && e.preventDefault();
                    toggleFilter(!openFilter);
                  }}
                  id="collapsible-section-header-icon"
                >
                  <FilterIcon fill={openFilter} />
                </a>
              </div>

              <div id="collapsible-section-search-filter">
                <Categories
                  type="notifications"
                  data={NotificationsCategoriesData}
                  selectedCategoryId={selectedCategory.value}
                  onCategorySelect={onCategorySelect}
                  toggleFilter={toggleFilter}
                />
              </div>
            </div>
          </div>
        </Col>
        <Col xs={9} sm={9} md={9} lg={9}>
          <div className={cx("o-notifications-listing-wrapper")}>
            <div className={cx("o-notifications-listing-header")}>
              <h2>{selectedCategory.category_name} Settings</h2>
            </div>
            <div className={cx("o-notifications-listing-list-wrapper")}>
              {selectedCategory.value === "networking" ? (
                <NetworkSettings
                  onToggle={toggleNotificationSettings}
                  onFrequencyChange={applyNotificationFrequency}
                />
              ) : selectedCategory.value === "topics" ? (
                <TopicsSettings onToggle={toggleNotificationSettings} />
              ) : selectedCategory.value === "webinars" ? (
                <WebinarsSettings onToggle={toggleNotificationSettings} />
              ) : selectedCategory.value === "resources" ? (
                <ResourcesSettings onToggle={toggleNotificationSettings} />
              ) : selectedCategory.value === "student_services" ? (
                <StudentServicesSettings
                  onToggle={toggleNotificationSettings}
                />
              ) : selectedCategory.value === "homefeed" ? (
                <HomeFeedSettings onToggle={toggleNotificationSettings} />
              ) : (
                <></>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default memo(NotificationsSettings);
