import React, { memo } from "react";
import { ShimmerPostItem } from "react-shimmer-effects";
import { Masonry } from "@mui/lab";

import { createModuleStyleExtractor } from "../../../utils/css";
import { Col } from "../../helpers/Grid/Col";
import { Row } from "../../helpers/Grid/Row";
import styles from "./WidgetStudentListView.module.scss";
import WidgetNetworkCard from "../../atoms/WidgetNetworkCard/WidgetNetworkCard";
import { isNull } from "../../../utils/validations";

import nostudentsImg from "../../../assets/images/discover_no_contacts.svg";

const cx = createModuleStyleExtractor(styles);

const WidgetStudentListView = ({
  studentsLoader = false,
  students = [],
  handleViewDetailsClick = () => {},
}) => {
  return (
    <>
      {studentsLoader ? (
        <Row>
          {[...Array(12)].map((_, index) => (
            <Col
              xs={12}
              sm={6}
              md={4}
              lg={4}
              key={`student_${index}`}
              styles={{ marginBottom: "10px" }}
            >
              <ShimmerPostItem
                card
                title
                cta
                imageType="circular"
                imageWidth={70}
                imageHeight={70}
                contentCenter
              />
            </Col>
          ))}
        </Row>
      ) : (
        <>
          {!studentsLoader && !students ? (
            <div className={cx("m-widget-students-list-view__empty")}>
              <img src={nostudentsImg} alt="No students" />
              <h3>Refine your search to find suitable ambassadors.</h3>
              <h4>No matching results found.</h4>
            </div>
          ) : (
            <div className={cx("m-widget-students-list-view__student-cards")}>
              <Masonry columns={3}>
                {!isNull(students) &&
                  students.map((user) => (
                    <div key={user.id}>
                      <WidgetNetworkCard
                        user={user}
                        showUserType={true}
                        handleViewDetailsClick={handleViewDetailsClick}
                      />
                    </div>
                  ))}
              </Masonry>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default memo(WidgetStudentListView);
