import React, { memo } from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import { CircularAvatar } from "../CircularAvatar/CircularAvatar";
import {
  generateFirstName,
  computeNetworkUserIntro,
} from "../../../utils/common";
import { INTERSTRIDE_LOGO_URL } from "../../../utils/constants";
import styles from "./WidgetNetworkCard.module.scss";

import arrowRight from "../../../assets/svg/arrow-right-network-card.svg";

const cx = createModuleStyleExtractor(styles);

const WidgetNetworkCard = ({
  user,
  handleViewDetailsClick = () => {},
  showPoweredBy = false,
  showUserType = false,
}) => {
  const {
    country_image,
    country_of_origin,
    user_profile,
    intro,
    user_type,
    major,
    graduation_year,
    degree,
    school_name,
    last_active_time,
    admin_position,
  } = user;
  // Full Name override based on user_type
  let { full_name } = user;
  if (user_type === "Student") {
    full_name = generateFirstName(full_name);
  }

  return (
    <div className={cx("a-widget-network-card")}>
      <div className={cx("a-widget-network-card__profile")}>
        <CircularAvatar
          src={user_profile}
          name={full_name}
          round={true}
          size={73}
          wrapperClass={cx("a-widget-network-card__profile__pic")}
        />
        <div className={cx("a-widget-network-card__profile__info")}>
          <div className={cx("a-widget-network-card__profile__info__line_one")}>
            <span>{full_name}</span>
            {showUserType && <span>{user_type}</span>}
          </div>
          <div className={cx("a-widget-network-card__profile__info__line_two")}>
            {user_type === "Admin" ? (
              <span>Admissions Team</span>
            ) : user_type === "Staff" ? (
              <span>{admin_position}</span>
            ) : major && graduation_year ? (
              <span>{major + ", " + graduation_year}</span>
            ) : (
              <span></span>
            )}
          </div>
          <div
            className={cx("a-widget-network-card__profile__info__line_three")}
          >
            {country_image && country_of_origin && (
              <>
                <img src={country_image} alt="flag" />
                <span>From {country_of_origin}</span>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={cx("a-widget-network-card__body")}>
        <p className={cx("a-widget-network-card__body__intro")}>
          {computeNetworkUserIntro(
            intro,
            user_type,
            major,
            degree,
            school_name
          )}
        </p>
        <div className={cx("a-widget-network-card__body__bottom")}>
          {last_active_time && (
            <div
              className={cx("a-widget-network-card__body__bottom__active-text")}
            >
              <div
                className={cx(
                  "a-widget-network-card__body__bottom__active-text__bullet"
                )}
              />
              {last_active_time}
            </div>
          )}
          <div
            className={cx("a-widget-network-card__body__bottom__view-details")}
            onClick={() => handleViewDetailsClick(user.id)}
          >
            <span>View full profile</span>
            <img src={arrowRight} alt="arrow" />
          </div>
        </div>
      </div>

      {showPoweredBy && (
        <div className={cx("a-widget-network-card__poweredby-logo")}>
          <h3>Powered by</h3>
          <img src={INTERSTRIDE_LOGO_URL} alt="interstride logo"></img>
        </div>
      )}
    </div>
  );
};
export default memo(WidgetNetworkCard);
