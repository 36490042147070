import React, { useEffect, useState, memo } from "react";
import { ShimmerThumbnail } from "react-shimmer-effects";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./ChatMapView.module.scss";

import noContactImg from "../../../assets/images/discover_no_contacts.svg";
import MarkerWorldMap from "../../atoms/MarkerWorldMap/MarkerWorldMap";
import DiscoverNetworkCard from "../../atoms/DiscoverNetworkCard/DiscoverNetworkCard";
import { groupBy } from "../../../utils/common";
import { isEmpty } from "../../../utils/validations";

const cx = createModuleStyleExtractor(styles);

const ChatMapView = ({
  contacts = [],
  isEmptyContacts,
  contactLoader,
  selectedSchoolId,
  handleMeeting = () => {},
}) => {
  const [mapLoader, setShowMapLoader] = useState(true);
  const [groupedContacts, setGroupedContacts] = useState({});
  const [isEmptyGroupedContacts, setIsEmptyGroupedContacts] = useState(true);
  const [selectedNationality, setSelectedNationality] = useState(false);

  useEffect(() => {
    if (contactLoader) return;

    setShowMapLoader(true);
    setSelectedNationality(false);

    if (!contacts || isEmpty(contacts) || isEmptyContacts) {
      setGroupedContacts({});
      setShowMapLoader(false);
    } else {
      const countryWiseAmbassadors = groupBy(
        contacts,
        // Group by country
        "country_of_origin"
      );

      if (!countryWiseAmbassadors || isEmpty(countryWiseAmbassadors)) {
        setGroupedContacts({});
        setShowMapLoader(false);
      } else {
        setGroupedContacts({ ...countryWiseAmbassadors });
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts]);

  useEffect(() => {
    if (!contactLoader) {
      if (groupedContacts && !isEmpty(groupedContacts)) {
        setIsEmptyGroupedContacts(false);
        setShowMapLoader(false);
      } else if (!mapLoader) setIsEmptyGroupedContacts(true);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupedContacts]);

  return (
    <div className={cx("m-chat-map-view")}>
      {contactLoader || mapLoader ? (
        <div className={cx("m-chat-map-view")}>
          <ShimmerThumbnail height={600} rounded />;
        </div>
      ) : !mapLoader && isEmptyGroupedContacts ? (
        <div className={cx("m-chat-map-view__empty")}>
          <img src={noContactImg} alt="noContacts" />
          <p className={cx("m-chat-map-view__empty_primary-text")}>
            Refine your search to find suitable ambassadors.
          </p>
          <p className={cx("m-chat-map-view__empty_secondary-text")}>
            No matching results found.
          </p>
        </div>
      ) : (
        <div className={cx("m-chat-map-view__main")}>
          <div className={cx("m-chat-map-view__main__map")}>
            <MarkerWorldMap
              data={groupedContacts}
              categoryLabel="nationality"
              valueLabel="user" // Staff + Ambassador
              valueType="list"
              onClick={(nationality) => {
                setSelectedNationality(nationality);
              }}
            />
          </div>
          <div className={cx("m-chat-map-view__main__list")}>
            {(selectedNationality
              ? groupedContacts?.[selectedNationality] ?? contacts
              : contacts
            ).map((user, index) => (
              <div key={user.id}>
                <DiscoverNetworkCard
                  index={index}
                  user={user}
                  handleMeeting={handleMeeting}
                  selectedSchoolId={selectedSchoolId}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
export default memo(ChatMapView);
