import React, { memo, useState } from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import { ROUTE_TYPES } from "../../../utils/common";
import styles from "./WidgetResourcesTab.module.scss";
import NSwitch from "../../atoms/NSwitch/NSwitch";
import WidgetWebinars from "../WidgetWebinars/WidgetWebinars";
import WidgetServices from "../WidgetServices/WidgetServices";
import WidgetResources from "../WidgetResources/WidgetResources";
import WidgetEmployment from "../WidgetEmployment/WidgetEmployment";
import WidgetSignUpOverlay from "../WidgetSignUpOverlay/WidgetSignUpOverlay";

const cx = createModuleStyleExtractor(styles);

const WidgetResourcesTab = ({ schoolData = {} }) => {
  const { school_module_permission } = schoolData;
  const [showSignupOverlay, setShowSignupOverlay] = useState(false);

  let switchOptions = [
    {
      key: "webinars",
      value: "Webinars",
      route_type: ROUTE_TYPES.WEBINAR,
      signup_overlay: true,
    },
    {
      key: "services",
      value: "Services",
      route_type: ROUTE_TYPES.SERVICE,
      signup_overlay: true,
    },
    {
      key: "resources",
      value: "Resources",
      route_type: ROUTE_TYPES.RESOURCE,
      signup_overlay: true,
    },
  ];

  school_module_permission["employment_data"] &&
    school_module_permission["opt_upload"] &&
    switchOptions.push({
      key: "employment_data",
      value: "Employment",
      route_type: ROUTE_TYPES.EMPLOYMENT_DATA,
    });

  const [tab, setTab] = useState(switchOptions[0]);

  const showOverlay = () => setShowSignupOverlay(true);

  const closeOverlay = () => setShowSignupOverlay(false);

  return (
    <>
      {showSignupOverlay && tab.signup_overlay && (
        <WidgetSignUpOverlay
          token={schoolData?.token}
          title={`Sign up to access ${tab.key}`}
          route_type={tab.route_type}
          closeOverlay={closeOverlay}
        />
      )}
      <div className={cx("m-widget-resources-tab")}>
        <h2 className={cx("m-widget-resources-tab__title")}>
          Get insider knowledge and boost your application
        </h2>
        <div className={cx("m-widget-resources-tab__switch")}>
          <NSwitch
            activeSide={tab}
            setActiveSide={setTab}
            options={switchOptions}
          />
        </div>

        {tab.key === switchOptions[0]?.key ? (
          <WidgetWebinars schoolData={schoolData} onSignupClick={showOverlay} />
        ) : tab.key === switchOptions[1]?.key ? (
          <WidgetServices schoolData={schoolData} onSignupClick={showOverlay} />
        ) : tab.key === switchOptions[2]?.key ? (
          <WidgetResources
            schoolData={schoolData}
            onSignupClick={showOverlay}
          />
        ) : (
          <WidgetEmployment schoolData={schoolData} />
        )}
      </div>
    </>
  );
};
export default memo(WidgetResourcesTab);
