import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { createModuleStyleExtractor } from "../../../utils/css";
import DiscoverNetworkFilters from "../../molecules/DiscoverNetworkFilters/DiscoverNetworkFilters";
import { RequestMeeting } from "../../molecules/RequestMeeting/RequestMeeting";
import styles from "./DicoverNetwork.module.scss";
import { getContactsList, getDiscoverNetworkFilters } from "../../../api/chat";
import ChatListView from "../../molecules/ChatListView/ChatListView";
import ChatMapView from "../../molecules/ChatMapView/ChatMapView";

const cx = createModuleStyleExtractor(styles);

const DicoverNetwork = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const route_view = searchParams.get("route_view")
    ? decodeURIComponent(searchParams.get("route_view"))
    : false;
  const route_id = searchParams.get("route_id")
    ? decodeURIComponent(searchParams.get("route_id"))
    : false;

  const [currentUser, setCurrentUser] = useState(null);
  const [networkFilterOptions, setNetworkFilterOptions] = useState([]);
  const [selectedNetworkFilters, setSelectedNetworkFilters] = useState({});
  const [contacts, setContacts] = useState([]);
  const [contactLoader, setContactsLoader] = useState(true);
  const [show, setIsShow] = useState(false);
  const [isEmptyContacts, setIsEmptyContacts] = useState(false);
  const [tab, setTab] = useState(route_view === "list" ? "right" : "left"); //Options -> left: Map View and right: List View

  const { selectedSchool = {} } = useSelector((store) => store.userState);

  useEffect(() => {
    const fetchDiscoverNetworkFilters = async () => {
      setContactsLoader(true);
      const response = await getDiscoverNetworkFilters(selectedSchool.id);
      if (response.success && response.data) {
        setNetworkFilterOptions(response.data);
        setContactsLoader(false);
      } else {
        setNetworkFilterOptions({});
        setContactsLoader(false);
      }
    };

    fetchDiscoverNetworkFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSchool]);

  useEffect(() => {
    const fetchContactsList = async () => {
      setContactsLoader(true);
      const response = await getContactsList(
        selectedNetworkFilters,
        selectedSchool.id
      );
      if (response.success && response.data) {
        const _contacts = response.data.networks_list.map((contact) =>
          contact.user_type === "Staff" && !contact.country_of_origin
            ? // Need to set US country for staff accounts without country
              {
                ...contact,
                country_of_origin: "United States",
                country_image: "https://flagpedia.net/data/flags/normal/us.png",
              }
            : contact
        );
        if (route_id) {
          _contacts.forEach(function (record, i) {
            if (record.id === parseInt(route_id)) {
              _contacts.splice(i, 1);
              _contacts.unshift(record);
            }
          });
          setContacts(_contacts);
          searchParams.delete("route_id");
        } else {
          setContacts(_contacts);
        }
        setContactsLoader(false);
        if (_contacts.length === 0) {
          setIsEmptyContacts(true);
        } else {
          setIsEmptyContacts(false);
        }
      } else {
        setContacts([]);
        setContactsLoader(false);
      }
    };

    fetchContactsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNetworkFilters, selectedSchool.id]);

  useEffect(() => {
    if (
      searchParams.has("id") &&
      searchParams.has("requestMeeting") &&
      contacts
    ) {
      const _user = contacts.find(
        (contact) => contact.id === parseInt(searchParams.get("id"))
      );
      if (_user && searchParams.get("requestMeeting") === "true") {
        setCurrentUser(_user);
        setIsShow(true);
        searchParams.delete("id");
        searchParams.delete("requestMeeting");
        setSearchParams(searchParams);
      }
    }
  }, [searchParams, contacts, setSearchParams]);

  const onChangeFilter = (key, item) => {
    let _selectedNetworkFilters = { ...selectedNetworkFilters };
    if (item === null) {
      delete _selectedNetworkFilters[key];
    } else {
      _selectedNetworkFilters[key] = item;
    }
    setSelectedNetworkFilters(_selectedNetworkFilters);
  };

  const onClearFilter = () => {
    if (Object.keys(selectedNetworkFilters).length > 0) {
      setSelectedNetworkFilters({});
    }
  };

  const handleMeeting = (user) => {
    setIsShow(true);
    setCurrentUser(user);
  };

  return (
    <div className={cx("o-discover-network")}>
      {currentUser && (
        <RequestMeeting
          show={show}
          handleClose={() => setIsShow(false)}
          user={currentUser}
        />
      )}
      <div className={cx("o-discover-network__header")}>
        <DiscoverNetworkFilters
          networkFilterOptions={networkFilterOptions}
          selectedNetworkFilters={selectedNetworkFilters}
          onChangeFilter={onChangeFilter}
          onClearFilter={onClearFilter}
          tab={tab}
          setTab={setTab}
        />
      </div>
      {tab === "left" ? (
        <ChatMapView
          contacts={contacts}
          contactLoader={contactLoader}
          isEmptyContacts={isEmptyContacts}
          selectedSchoolId={selectedSchool.id}
          handleMeeting={handleMeeting}
        />
      ) : (
        <ChatListView
          contacts={contacts}
          contactLoader={contactLoader}
          isEmptyContacts={isEmptyContacts}
          selectedSchoolId={selectedSchool.id}
          handleMeeting={handleMeeting}
        />
      )}
    </div>
  );
};

export default memo(DicoverNetwork);
