import React from "react";
import { APIProvider, Map } from "@vis.gl/react-google-maps";

import { US_COORDIANTES } from "../../../utils/constants";
import { createModuleStyleExtractor } from "../../../utils/css";
import { GOOGLE_MAP_KEY, GOOGLE_MAP_ID } from "../../../config/config";
import PoiMarkers from "./PoiMarkers/PoiMarkers";
import styles from "./GoogleMap.module.scss";

const cx = createModuleStyleExtractor(styles);

export default function GoogleMap({
  data = [],
  defaultZoom = 4,
  defaultCenter = US_COORDIANTES,
}) {
  return (
    <div className={cx("a-google-map")}>
      <APIProvider apiKey={GOOGLE_MAP_KEY}>
        <Map
          defaultZoom={defaultZoom}
          defaultCenter={defaultCenter}
          mapId={GOOGLE_MAP_ID}
          mapTypeControlOptions={{ mapTypeIds: [] }} // This disables map type control options
        >
          {data && <PoiMarkers data={data} />}
        </Map>
      </APIProvider>
    </div>
  );
}
