import React, { useState, useRef, useEffect, useCallback } from "react";
import { useMap, AdvancedMarker } from "@vis.gl/react-google-maps";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { ClickAwayListener } from "@mui/material";

import { createModuleStyleExtractor } from "../../../../utils/css";
import styles from "./PoiMarkers.module.scss";
import InfoWindow from "../InfoWindow/InfoWindow";
import { isEmpty } from "../../../../utils/validations";

import CampusMapDefaultIcon from "../../../../assets/svg/campus-map-default";
import CampusMapRestaurantIcon from "../../../../assets/svg/campus-map-restaurant";
import CampusMapStudentOfficesIcon from "../../../../assets/svg/campus-map-student-offices";
import CampusMapBankIcon from "../../../../assets/svg/campus-map-bank";
import CampusMapUniversityIcon from "../../../../assets/svg/campus-map-university";

const cx = createModuleStyleExtractor(styles);
const INITIAL_INFO_WINDOW_STATE = { show: false, place_id: null };

export default function PoiMarkers({ data = {} }) {
  const [markers, setMarkers] = useState({}); // List of markers - Used for clusters
  const [infoWindow, setInfoWindow] = useState(INITIAL_INFO_WINDOW_STATE);

  const map = useMap(); // Map instance
  const clusterer = useRef(null); // Cluster reference

  // Mint references for new markers
  const setMarkerRef = (marker = null, key = "") => {
    // Safety check
    if ((marker && markers[key]) || (!marker && !markers[key])) return;

    setMarkers((current) => {
      if (marker) {
        return { ...current, [key]: marker };
      } else {
        const newMarkers = { ...current };
        delete newMarkers[key];
        return newMarkers;
      }
    });
  };

  const handleClickMarker = useCallback(
    (
      event = {},
      place_id = null,
      latLangLiteral = null,
      openInfoWindow = true
    ) => {
      // Safety checks
      if (!map || !place_id || (!event?.latLng && !latLangLiteral)) {
        setInfoWindow(INITIAL_INFO_WINDOW_STATE);
        return;
      }
      // Pan map
      map.panTo(latLangLiteral || event?.latLng);
      // Adjust zoom
      if (map.getZoom() < 15) {
        // If zoomed out
        map.setZoom(15);
      }
      // Open info window
      openInfoWindow &&
        setInfoWindow({
          show: true,
          place_id,
        });
    },
    [map]
  );

  // Initialize MarkerClusterer, if the map has changed
  useEffect(() => {
    if (map && !clusterer.current) {
      clusterer.current = new MarkerClusterer({ map });
    }
  }, [map]);

  // Update markers, if the markers array has changed
  useEffect(() => {
    clusterer.current?.clearMarkers && clusterer.current.clearMarkers();
    clusterer.current?.addMarkers &&
      clusterer.current.addMarkers(Object.values(markers));
  }, [markers]);

  // Track pois and select first place
  useEffect(() => {
    if (!isEmpty(data?.pois?.[0]?.location)) {
      handleClickMarker(
        null,
        data?.pois?.[0]?.id,
        data?.pois?.[0].location,
        false
      );
    }
  }, [data?.pois, handleClickMarker]);

  return (
    <>
      {data?.pois && (
        <div className={cx("a-pois-list-wrapper")}>
          <div className={cx("a-pois-list")}>
            {data.pois?.map((poi, index) => (
              <InfoWindow
                key={index}
                poi={poi}
                handleClick={handleClickMarker}
              />
            ))}
          </div>
        </div>
      )}
      {data?.pois?.map((poi) => (
        <AdvancedMarker
          key={poi?.key}
          position={poi?.location}
          clickable={true}
          ref={(marker) => setMarkerRef(marker, poi.key)}
          onClick={(event) => handleClickMarker(event, poi?.id, null, true)}
        >
          <div className={cx("a-poi-marker")}>
            {infoWindow.show && infoWindow.place_id === poi?.id && (
              <ClickAwayListener
                onClickAway={() => setInfoWindow(INITIAL_INFO_WINDOW_STATE)}
              >
                <div className={cx("a-poi-marker__info-window")}>
                  <InfoWindow handleClick={handleClickMarker} poi={poi} />
                </div>
              </ClickAwayListener>
            )}
            <div
              className={cx([
                "a-poi-marker__pin",
                infoWindow.show && infoWindow.place_id === poi?.id
                  ? "a-poi-marker__pin--expanded"
                  : infoWindow.show && infoWindow.place_id !== poi?.id
                  ? "a-poi-marker__pin--hidden"
                  : "",
              ])}
            >
              {data?.category?.name === "Restaurants" ? (
                <CampusMapRestaurantIcon />
              ) : data?.category?.name === "Banks" ? (
                <CampusMapBankIcon />
              ) : data?.category?.name === "Universities" ? (
                <CampusMapUniversityIcon />
              ) : data?.category?.name === "Student offices" ? (
                <CampusMapStudentOfficesIcon />
              ) : (
                <CampusMapDefaultIcon />
              )}
            </div>
          </div>
        </AdvancedMarker>
      ))}
    </>
  );
}
