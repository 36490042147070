import React, { memo, useState, useEffect } from "react";
import { ShimmerThumbnail } from "react-shimmer-effects";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./WidgetEmployment.module.scss";
import { ROUTE_TYPES } from "../../../utils/common";
import { getEmploymentData } from "../../../api/widget";
import HorizontalBarChart from "../../atoms/HorizontalBarChart/HorizontalBarChart";
import WidgetSignUpOverlay from "../WidgetSignUpOverlay/WidgetSignUpOverlay";

const cx = createModuleStyleExtractor(styles);

const WidgetEmployment = ({ schoolData, onSignupClick = () => {} }) => {
  const [graphData, setGraphData] = useState({});
  const [graphLoader, setGraphLoader] = useState(true);

  const WIDGET_GRAPH_STATIC_LABELS = [
    "Associate",
    "Bachelors",
    "Doctoral",
    "Masters",
    "MBA",
    "Post-doctoral",
    "University staff",
    "Others",
  ];
  const WIDGET_GRAPH_STATIC_DATA = [98, 87, 95, 76, 65, 91, 68, 82];

  useEffect(() => {
    (async () => {
      const response = await getEmploymentData(schoolData?.token);

      if (response?.success && response?.data?.length > 0) {
        // Process data
        let _labels = [];
        let _data = [];
        response?.data?.slice(0, 10).forEach((item) => {
          _labels.push(item?.company);
          _data?.push(item?.count);
        });
        setGraphData({
          labels: _labels,
          data: _data,
        });
      } else {
        setGraphData({
          labels: [],
          data: [],
        });
      }
      setGraphLoader(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onGraphClick = () => {
    const element = document.getElementById("overlay-graph");
    element?.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  return (
    <div className={cx("m-widget-employment")}>
      {graphLoader ? (
        <div className={cx("m-widget-employment__loader")}>
          <ShimmerThumbnail rounded />
        </div>
      ) : (
        <div className={cx("m-widget-employment__data")}>
          <h2 className={cx("m-widget-employment__data__title")}>
            Top employers for international graduates
          </h2>
          <div className={cx("m-widget-employment__data__graph")}>
            <HorizontalBarChart
              labels={graphData.labels}
              data={graphData.data}
              enableCursorPointer
              onClickHandler={onGraphClick}
            />
          </div>
          <h2 className={cx("m-widget-employment__data__title")}>
            Degrees of international graduates with employment
          </h2>
          <div
            className={cx("m-widget-employment__data__graph")}
            id="overlay-graph"
          >
            <WidgetSignUpOverlay
              closeOption={false}
              token={schoolData?.token}
              title={"Sign up to access employment data"}
              route_type={ROUTE_TYPES.EMPLOYMENT_DATA}
            />
            <HorizontalBarChart
              labels={WIDGET_GRAPH_STATIC_LABELS}
              data={WIDGET_GRAPH_STATIC_DATA}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default memo(WidgetEmployment);
