import React, { useState, useEffect } from "react";
import { Grid, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { sortBy } from "lodash";

import TopEmployerGraph from "../../molecules/TopEmployerGraph/TopEmployerGraph";
import AlumniDegreesGraph from "../../molecules/AlumniDegreesGraph/AlumniDegreesGraph";
import AlumniNationalityGraph from "../../molecules/AlumniNationalityGraph/AlumniNationalityGraph";
import AlumniMajorsGraph from "../../molecules/AlumniMajorsGraph/AlumniMajorsGraph";
import EmploymentDataTable from "../../molecules/EmploymentDataTable/EmploymentDataTable";
import Switch from "../../atoms/Switch/Switch";
import { createModuleStyleExtractor } from "../../../utils/css";
import { getFiltersData } from "../../../api/employmentDataHub";
import {
  initialListingStateDataListing,
  initialStateTopEmployerGraph,
  initialStateDegreesGraph,
  initialStateNationalityGraph,
  initialStateMajorsGraph,
} from "./EmploymentDataHubContants";
import styles from "./EmploymentDataHub.module.scss";

import InformationIcon from "../../../assets/Icons/Information.svg";

const cx = createModuleStyleExtractor(styles);

export default function EmploymentDataHub() {
  const [tab, setTab] = useState("left"); // "left" for Graphs and "right" for Data
  // Global shared filter data for all graphs and tables
  const [filters, setFilters] = useState({
    degree: [],
    major: [],
    nationality: [],
    year: [],
    employment_type: [],
    country: [],
  });
  // Data listing table state
  const [tableState, setTableState] = useState(initialListingStateDataListing);
  const [tableSearch, setTableSearch] = useState("");
  const [openFilterDataTable, toggleFilterDataTable] = useState(false);
  // Employer Graph state
  const [stateTopEmp, setStateTopEmp] = useState(initialStateTopEmployerGraph);
  const [openFilterTopEmp, toggleFilterTopEmp] = useState(false);
  // Degrees Graph state
  const [stateDegrees, setStateDegrees] = useState(initialStateDegreesGraph);
  const [openFilterDegrees, toggleFilterDegrees] = useState(false);
  // Nationality Graph state
  const [stateNationality, setStateNationality] = useState(
    initialStateNationalityGraph
  );
  const [openFilterNationality, toggleFilterNationality] = useState(false);
  // Majors Graph state
  const [stateMajors, setStateMajors] = useState(initialStateMajorsGraph);
  const [openFilterMajors, toggleFilterMajors] = useState(false);

  // Redux states
  const { selectedSchool, permissions } = useSelector(
    (state) => state.userState
  );
  const { id, name } = selectedSchool || {};

  // Call filters API to get data upon root component load / school change
  useEffect(() => {
    // Stops bruteforce api call on link hit if permission if false
    if (!permissions["employment_data"]) return;

    (async () => {
      // Get filter data
      const response = await getFiltersData(id);
      if (response.success) {
        // Process options data
        let _year = [];
        let _nationality = [];
        let _major = [];
        let _degree = [];
        let _employment_type = [];
        let _country = [];
        if (response?.data?.year?.length > 0)
          response.data.year.forEach((element) =>
            _year.push({ value: element, label: element })
          );
        if (response?.data?.nationality?.length > 0)
          response.data.nationality.forEach((element) =>
            _nationality.push({ value: element, label: element })
          );
        if (response?.data?.major?.length > 0)
          response.data.major.forEach((element) =>
            _major.push({ value: element, label: element })
          );
        if (response?.data?.degree?.length > 0)
          response.data.degree.forEach((element) =>
            _degree.push({ value: element, label: element })
          );
        if (response?.data?.employment_type?.length > 0)
          response.data.employment_type.forEach((element) => {
            if (element === "CPT") {
              _employment_type.unshift({ value: element, label: element });
            } else {
              _employment_type.push({ value: element, label: element });
            }
          });
        if (response?.data?.country?.length > 0)
          response.data.country.forEach((element) =>
            _country.push({ value: element, label: element })
          );
        setFilters({
          year: sortBy(_year, "label"),
          nationality: sortBy(_nationality, "label"),
          major: sortBy(_major, "label"),
          degree: sortBy(_degree, "label"),
          employment_type: _employment_type,
          country: sortBy(_country, "label"),
        });
      }
    })();
  }, [id, permissions]);

  const processGraphClick = (coordinates, type, geoGraphStateRef = null) => {
    if (!coordinates?.label || !type) return;

    const filterData = { label: coordinates?.label, value: coordinates?.label };

    // Set search
    setTableSearch(type === "TopEmployer" ? coordinates.label : "");

    // Set table filters
    let _tableState = {
      ...initialListingStateDataListing,
    };
    if (type === "TopEmployer") {
      _tableState.selectedFilters = {
        ..._tableState.selectedFilters,
        ...stateTopEmp?.selectedFilters,
      };
    } else if (type === "AlumniDegrees") {
      _tableState.selectedFilters = {
        ..._tableState.selectedFilters,
        ...stateDegrees?.selectedFilters,
        degree: filterData,
      };
    } else if (type === "AlumniNationality") {
      _tableState.selectedFilters = {
        ..._tableState.selectedFilters,
        ...(geoGraphStateRef?.selectedFilters ||
          stateNationality?.selectedFilters),
        nationality: filterData,
      };
    } else if (type === "AlumniMajors") {
      _tableState.selectedFilters = {
        ..._tableState.selectedFilters,
        ...stateMajors?.selectedFilters,
        major: filterData,
      };
    }
    setTableState(_tableState);

    // Open filter tab
    window.innerWidth <= 1180 && toggleFilterDataTable(true);

    //Switch Tab
    setTab("right");
  };

  useEffect(() => {
    if (tab === "left") {
      // Reset table sorting and per page on tab change to "Left"
      setTableState((current) => ({
        ...current,
        order_by: null,
        order_direction: null,
        per_page: 10,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  return (
    <div className={cx("o-hub")}>
      {permissions["employment_data"] && (
        <>
          <div className={cx("o-hub__header")}>
            <h1 className={cx("o-hub__header__title")}>
              Employment Data
              <Tooltip
                title={`The data hub provides employment information of international graduates from ${name}`}
                enterTouchDelay={0}
              >
                <img
                  className={cx("o-hub__header__info-icon")}
                  src={InformationIcon}
                  alt="info"
                />
              </Tooltip>
            </h1>
            <Switch
              activeSide={tab}
              leftTitle="Graphs"
              rightTitle="Data"
              setActiveSide={setTab}
            />
          </div>
          {tab === "left" ? (
            <div className={cx("o-hub__body")}>
              <TopEmployerGraph
                filters={filters}
                state={stateTopEmp}
                setState={setStateTopEmp}
                openFilter={openFilterTopEmp}
                toggleFilter={toggleFilterTopEmp}
                schoolId={id}
                processGraphClick={processGraphClick}
              />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <AlumniDegreesGraph
                    filters={filters}
                    state={stateDegrees}
                    setState={setStateDegrees}
                    openFilter={openFilterDegrees}
                    toggleFilter={toggleFilterDegrees}
                    schoolId={id}
                    processGraphClick={processGraphClick}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <AlumniNationalityGraph
                    filters={filters}
                    state={stateNationality}
                    setState={setStateNationality}
                    openFilter={openFilterNationality}
                    toggleFilter={toggleFilterNationality}
                    schoolId={id}
                    processGraphClick={processGraphClick}
                  />
                </Grid>
              </Grid>
              <AlumniMajorsGraph
                filters={filters}
                state={stateMajors}
                setState={setStateMajors}
                openFilter={openFilterMajors}
                toggleFilter={toggleFilterMajors}
                schoolId={id}
                processGraphClick={processGraphClick}
              />
            </div>
          ) : (
            <EmploymentDataTable
              tableState={tableState}
              setTableState={setTableState}
              tableSearch={tableSearch}
              setTableSearch={setTableSearch}
              openFilter={openFilterDataTable}
              toggleFilter={toggleFilterDataTable}
              filters={filters}
              selectedSchoolId={id}
            />
          )}
        </>
      )}
    </div>
  );
}
