import React, { useEffect, useState, memo } from "react";
import { ShimmerThumbnail } from "react-shimmer-effects";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./WidgetStudentMapView.module.scss";

import MarkerWorldMap from "../../atoms/MarkerWorldMap/MarkerWorldMap";
import { groupBy } from "../../../utils/common";
import { isEmpty } from "../../../utils/validations";
import WidgetNetworkCard from "../../atoms/WidgetNetworkCard/WidgetNetworkCard";

import noContactImg from "../../../assets/images/discover_no_contacts.svg";

const cx = createModuleStyleExtractor(styles);

const WidgetStudentMapView = ({
  students = [],
  studentsLoader,
  handleViewDetailsClick = () => {},
}) => {
  const [mapLoader, setShowMapLoader] = useState(true);
  const [groupedContacts, setGroupedContacts] = useState({});
  const [isEmptyGroupedContacts, setIsEmptyGroupedContacts] = useState(true);
  const [selectedNationality, setSelectedNationality] = useState(false);

  useEffect(() => {
    if (studentsLoader) return;

    setShowMapLoader(true);
    setSelectedNationality(false);

    if (!students || isEmpty(students)) {
      setGroupedContacts({});
      setShowMapLoader(false);
    } else {
      const countryWiseAmbassadors = groupBy(
        students,
        // Group by country
        "country_of_origin"
      );

      if (!countryWiseAmbassadors || isEmpty(countryWiseAmbassadors)) {
        setGroupedContacts({});
        setShowMapLoader(false);
      } else {
        setGroupedContacts({ ...countryWiseAmbassadors });
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [students]);

  useEffect(() => {
    if (!studentsLoader) {
      if (groupedContacts && !isEmpty(groupedContacts)) {
        setIsEmptyGroupedContacts(false);
        setShowMapLoader(false);
      } else if (!mapLoader) setIsEmptyGroupedContacts(true);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupedContacts]);

  return (
    <div className={cx("m-widget-students-map-view")}>
      {studentsLoader || mapLoader ? (
        <div className={cx("m-widget-students-map-view")}>
          <ShimmerThumbnail height={400} rounded />;
        </div>
      ) : !mapLoader && isEmptyGroupedContacts ? (
        <div className={cx("m-widget-students-map-view__empty")}>
          <img src={noContactImg} alt="No students" />
          <h3>Refine your search to find suitable ambassadors.</h3>
          <h4>No matching results found.</h4>
        </div>
      ) : (
        <div className={cx("m-widget-students-map-view__main")}>
          <div className={cx("m-widget-students-map-view__main__map")}>
            <MarkerWorldMap
              height={400}
              data={groupedContacts}
              categoryLabel="nationality"
              valueLabel="ambassador"
              valueType="list"
              onClick={(nationality) => {
                setSelectedNationality(nationality);
              }}
            />
          </div>
          <div className={cx("m-widget-students-map-view__main__list")}>
            {(selectedNationality
              ? groupedContacts?.[selectedNationality] ?? students
              : students
            ).map((user) => (
              <div key={user.id}>
                <WidgetNetworkCard
                  user={user}
                  handleViewDetailsClick={handleViewDetailsClick}
                  showUserType={true}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
export default memo(WidgetStudentMapView);
