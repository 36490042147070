import React, { memo, useEffect, useState } from "react";

import NSwitch from "../../atoms/NSwitch/NSwitch";
import { createModuleStyleExtractor } from "../../../utils/css";
import MuiSelect from "../../atoms/MuiSelect/MuiSelect";
import styles from "./WidgetNetworkFilters.module.scss";
import { getSelectOptions } from "../../../utils/noop";

import FilterIcon from "../../../assets/images/filter-icon.svg";

const cx = createModuleStyleExtractor(styles);

const WidgetNetworkFilters = ({
  networkFilterOptions = {},
  selectedNetworkFilters = {},
  onChangeFilter = () => {},
  tabViewOptions = {},
  tabView = {},
  setTabView = () => {},
}) => {
  const [filtersOpen, setFiltersOpen] = useState(false);

  const countryOptions = getSelectOptions(networkFilterOptions.countries);
  const degreeOptions = getSelectOptions(networkFilterOptions.degree);
  const majorOptions = getSelectOptions(networkFilterOptions.major);

  // This Effect ensures filter is visible upon resizing
  useEffect(() => {
    if (window?.innerWidth > -930) setFiltersOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={cx("m-widget-network-filter")}>
      <div className={cx("m-widget-network-filter__heading")}>
        <h2>Filters</h2>
        <img
          src={FilterIcon}
          alt="filter"
          onClick={() => setFiltersOpen((current) => !current)}
        />
      </div>
      {filtersOpen && (
        <div className={cx("m-widget-network-filter__items")}>
          <div className={cx("m-widget-network-filter__items__filter")}>
            <MuiSelect
              label=""
              placeholder="Filter by country"
              value={
                countryOptions
                  ? countryOptions.find(
                      (option) =>
                        option.value === selectedNetworkFilters?.country
                    )
                  : ""
              }
              options={countryOptions}
              onChange={(item) =>
                onChangeFilter("country", item ? item?.value : null)
              }
              justifyContent
              isClearable={true}
            />
          </div>
          <div className={cx("m-widget-network-filter__items__filter")}>
            <MuiSelect
              label=""
              placeholder="Filter by degree"
              value={
                degreeOptions
                  ? degreeOptions.find(
                      (option) =>
                        option.value === selectedNetworkFilters?.degree
                    )
                  : ""
              }
              options={degreeOptions}
              onChange={(item) =>
                onChangeFilter("degree", item ? item.value : null)
              }
              justifyContent
              isClearable={true}
            />
          </div>
          <div className={cx("m-widget-network-filter__items__filter")}>
            <MuiSelect
              label=""
              placeholder="Filter by major"
              value={
                majorOptions
                  ? majorOptions.find(
                      (option) => option.value === selectedNetworkFilters?.major
                    )
                  : ""
              }
              options={majorOptions}
              onChange={(item) =>
                onChangeFilter("major", item ? item.value : null)
              }
              justifyContent
              isClearable={true}
            />
          </div>
          <div className={cx("m-widget-network-filter__items__switch")}>
            <NSwitch
              activeSide={tabView}
              options={tabViewOptions}
              setActiveSide={setTabView}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default memo(WidgetNetworkFilters);
