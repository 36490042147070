import React, { memo, useId } from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./ChatListView.module.scss";
import { Col } from "../../helpers/Grid/Col";
import { Row } from "../../helpers/Grid/Row";
import DiscoverNetworkCard from "../../atoms/DiscoverNetworkCard/DiscoverNetworkCard";

import noContactImg from "../../../assets/images/discover_no_contacts.svg";
import { ShimmerPostItem } from "react-shimmer-effects";

const cx = createModuleStyleExtractor(styles);

const ChatListView = ({
  contacts = [],
  isEmptyContacts,
  contactLoader,
  selectedSchoolId,
  handleMeeting = () => {},
}) => {
  const id = useId();

  return (
    <div className={cx("m-chat-list-view")}>
      {contactLoader ? (
        <div className={cx("m-chat-list-view__users")}>
          <Row>
            {[...Array(12)].map((_, index) => (
              <Col
                xs={12}
                sm={6}
                md={3}
                lg={3}
                key={`${id}_${index}`}
                styles={{ marginBottom: "10px" }}
              >
                <ShimmerPostItem
                  card
                  title
                  cta
                  imageType="circular"
                  imageWidth={70}
                  imageHeight={70}
                  contentCenter
                />
              </Col>
            ))}
          </Row>
        </div>
      ) : !contactLoader && isEmptyContacts ? (
        <div className={cx("m-chat-list-view__users_no_network")}>
          <img src={noContactImg} alt="noContacts" />
          <p className={cx("m-chat-list-view__users_no_network_primary-text")}>
            Refine your search to find suitable ambassadors.
          </p>
          <p
            className={cx("m-chat-list-view__users_no_network_secondary-text")}
          >
            No matching results found.
          </p>
        </div>
      ) : (
        <div className={cx("m-chat-list-view__users__cards")}>
          {contacts?.map((user) => (
            <div key={user.id}>
              <DiscoverNetworkCard
                user={user}
                handleMeeting={handleMeeting}
                selectedSchoolId={selectedSchoolId}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default memo(ChatListView);
