import React from "react";

export default function CampusMapDefaultIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="43"
      viewBox="0 0 36 43"
      fill="none"
    >
      <path
        d="M36 17.623C36 27.356 18 43 18 43S0 27.356 0 17.623 8.059 0 18 0s18 7.89 18 17.623"
        fill="var(--primary-color)"
      />
    </svg>
  );
}
