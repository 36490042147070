import moment from "moment";
import { default as momentTz } from "moment-timezone";
import { isEmpty, isNull } from "lodash";

import toastify from "./toast";
import "../index.css";
import { ROUTES, UNPROTECTED_ROUTES, WIDGET_PATHS } from "../routes";
import { MICROSOFT_CLARITY_ID } from "../config/config";
import { store } from "../redux/store";
import { WELCOME_MESSAGE_FRESH_CHAT } from "./constants";
import { validateAttribute } from "./validations";

/**
 * Create query string url
 * @param {*} url
 * @param {*} options
 * @returns
 */
export const createQueryStringURL = (url, options = {}) => {
  const queryString = Object.keys(options)
    .map((option) => `${option}=${options[option]}`)
    .join("&");
  return `${url}?${queryString}`;
};

export const renderTimestamp = (timestamp) => {
  let prefix = "";
  if (new Date(timestamp).getTime() === new Date(1990, 5, 19, 12).getTime())
    return "a min ago";
  const timeDiff = Math.round(
    (new Date().getTime() - new Date(timestamp).getTime()) / 60000
  );
  if (timeDiff < 1) {
    // less than one minute ago
    prefix = "just now";
  } else if (timeDiff < 60 && timeDiff >= 1) {
    // less than sixty minutes ago
    prefix = `${timeDiff} min ago`;
  } else if (timeDiff < 24 * 60 && timeDiff >= 60) {
    // less than 24 hours
    prefix = `${Math.round(timeDiff / 60)} hours ago`;
  } else if (timeDiff < 31 * 24 * 60 && timeDiff > 24 * 60) {
    // less than 7 days
    prefix = `${Math.round(timeDiff / (60 * 24))} days ago`;
  } else if (timeDiff < 12 * 31 * 24 * 60 && timeDiff > 31 * 24 * 60) {
    // less than 12 month
    prefix = `${Math.round(timeDiff / (31 * 24 * 60))} months ago`;
  } else {
    // few month ago
    prefix = `few month ago`;
  }
  return prefix;
};
export const createFriendlyTitle = (title = "") => {
  return title?.length > 0
    ? title
        ?.split(" ")
        .map((name) => name.charAt(0).toUpperCase() + name.substring(1))
        .join(" ")
    : "";
};

export const validateChatAttachment = (file) => {
  const allowedChatAttachments = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "video/mp4",
    "application/pdf",
    "application/msword",
    "text/csv",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "text/plain",
    "audio/mpeg",
  ];
  if (!allowedChatAttachments.includes(file.type)) {
    toastify(
      "Please select a valid file (png, jpg, jpeg, video/mp4, pdf, doc)",
      "error"
    );
    return 401;
  }
  const size = parseFloat(file.size / (1024 * 1024)).toFixed(2); // in MB
  if (size > 5) {
    toastify("Can't attach file size more than 5 MB", "error");
    return 401;
  }

  return 200; //ok
};

export function generateRandomConversationId(min, max, excluded = []) {
  var num = Math.floor(Math.random() * (max - min + 1)) + min;
  return excluded.includes(num)
    ? generateRandomConversationId(min, max, excluded)
    : num;
}
export const extractMessage = (data) => {
  const {
    message,
    sender_id,
    sender_name,
    sender_image,
    shared_file,
    send_at,
    message_id,
    file_name,
    request_meeting,
  } = data;
  return {
    message,
    sender_id,
    sender_name,
    sender_image,
    send_at,
    id: message_id,
    ...(file_name && { file_name }),
    ...(shared_file && { shared_file }),
    conversation_type: request_meeting ? "request_meeting" : "simple",
  };
};

export const createFreshChatPreview = (data = {}, conversation_id) => {
  const messageTimeStamp = new Date();

  // Process welcome message
  let welcomeMessage = WELCOME_MESSAGE_FRESH_CHAT;
  welcomeMessage = welcomeMessage?.replace(
    "{first_name}",
    data?.full_name?.split(" ")[0]
  );
  welcomeMessage = welcomeMessage?.replace(
    "{country}",
    data?.country_of_origin
  );
  if (data?.user_type === "Alumni") {
    welcomeMessage = welcomeMessage?.replace("{user_type}", "an alumni");
  } else if (data?.user_type === "Student") {
    welcomeMessage = welcomeMessage?.replace("{user_type}", "a student");
  } else {
    welcomeMessage = welcomeMessage?.replace("{user_type}", "a staff");
  }
  welcomeMessage = welcomeMessage?.replace("{university}", data?.school_name);

  const preview = {
    latest_message: welcomeMessage,
    conversation_id,
    title: data?.full_name ? data?.full_name : "",
    time: messageTimeStamp,
    count: 0,
    user_image: data?.user_profile || null,
    receiver_id: data.id,
    user_deleted: false,
    mute_chat: false,
    account_type: data?.account_type,
  };

  const conversation = {
    chat: {
      conversation_id,
      conversation_window_title: data?.full_name ? data?.full_name : "",
      mute_chat: false,
    },
    group_details: {},
    user_info: {
      ...data,
    },
    messages: [
      {
        id: conversation_id,
        message: welcomeMessage,
        sender_name: data?.full_name,
        sender_image: data?.user_profile,
        conversation_type: "simple",
        send_at: messageTimeStamp,
        sender_id: data?.id,
        shared_file: "",
        file_name: "",
        account_type: data?.account_type,
      },
    ],
    current_page: 1,
    total_messsages: 1,
    total_pages: 1,
    next_page: null,
  };

  return {
    preview,
    conversation,
  };
};

export const scrollToActiveConversation = (conversation_id) => {
  const element = document.getElementById(
    `chat_contact_list-interstride--${conversation_id}`
  );
  element && element.scrollIntoView({ behavior: "smooth", block: "center" });
};

export const openInNewTab = (url) => {
  window.open(url, "_blank", "noopener,noreferrer");
};

export const detectDispalyMode = (isMobile, flag) => {
  if (!isMobile) return "block";
  return flag ? "block" : "none";
};

export const swiperConfig = (config) => {
  return {
    delta: 10, // min distance(px) before a swipe starts. *See Notes*
    preventDefaultTouchmoveEvent: false, // call e.preventDefault *See Details*
    trackTouch: true, // track touch input
    trackMouse: false, // track mouse input
    rotationAngle: 0, // set a rotation angle
    ...config,
  };
};
export const createtTime = (date, number, add) => {
  return moment.utc(date).add(number, add).format("hh:mm a");
};

export const processWebinarsCalendarEvents = (
  upcomingWebinars,
  pastWebinars
) => {
  const webinarsData = upcomingWebinars.concat(pastWebinars);
  let calendarEvents = [];

  for (let i = 0; i < webinarsData.length; i++) {
    let startTime = moment.utc(webinarsData[i].webinar_time).format("hh:mm a");
    let endTime = createtTime(
      webinarsData[i].webinar_time,
      webinarsData[i] &&
        webinarsData[i].duration &&
        webinarsData[i].duration !== null
        ? webinarsData[i].duration.split(" ")[0]
        : 0,
      "minutes"
    );

    let startDateTime = webinarsData[i].webinar_date + " " + startTime;
    let endDateTime = webinarsData[i].webinar_date + " " + endTime;

    calendarEvents.push({
      id: webinarsData[i].id,
      title: webinarsData[i].name,
      address: webinarsData[i].location,
      start:
        startDateTime && moment(startDateTime, "YYYY-MM-DD HH:mm").toDate(),
      end: endDateTime && moment(endDateTime, "YYYY-MM-DD HH:mm").toDate(),
      type: webinarsData[i].type,
      rss_feeds: webinarsData[i].rss_feeds,
      rss_feed_id:
        webinarsData[i].rss_feed_id && webinarsData[i].rss_feed_id != null
          ? webinarsData[i].rss_feed_id
          : null,
      webinarType: i <= upcomingWebinars.length - 1 ? "upcoming" : "past",
    });
  }

  return calendarEvents;
};

export const processWebinarsCalendarEventsV2 = (webinarsData) => {
  let calendarEvents = [];

  for (let i = 0; i < webinarsData.length; i++) {
    let startTime = moment.utc(webinarsData[i].webinar_time).format("hh:mm a");
    let endTime = createtTime(
      webinarsData[i].webinar_time,
      webinarsData[i] &&
        webinarsData[i].duration &&
        webinarsData[i].duration !== null
        ? webinarsData[i].duration.split(" ")[0]
        : 0,
      "minutes"
    );

    let startDateTime = webinarsData[i].webinar_date + " " + startTime;
    let endDateTime = webinarsData[i].webinar_date + " " + endTime;

    calendarEvents.push({
      id: webinarsData[i].id,
      title: webinarsData[i].name,
      address: webinarsData[i].location,
      start:
        startDateTime && moment(startDateTime, "YYYY-MM-DD HH:mm").toDate(),
      end: endDateTime && moment(endDateTime, "YYYY-MM-DD HH:mm").toDate(),
      type: webinarsData[i].type,
      rss_feeds: webinarsData[i].rss_feeds,
      rss_feed_id:
        webinarsData[i].rss_feed_id && webinarsData[i].rss_feed_id != null
          ? webinarsData[i].rss_feed_id
          : null,
      // webinarType: i <= upcomingWebinars.length - 1 ? "upcoming" : "past",
    });
  }

  return calendarEvents;
};

export const applyTheme = (color) => {
  const root = document.querySelector(":root");
  if (root) {
    root.style.setProperty("--primary-color", color);
  }
};

export const convertObjectToFormData = (objectName, data) => {
  const formData = new FormData();

  const keys = Object.keys(data);

  keys.forEach((key) => {
    if (Array.isArray(data[key])) {
      data[key].map((value, index) =>
        formData.append(`${objectName}[${key}][${index}]`, value)
      );
    } else formData.append(`${objectName}[${key}]`, data[key]);
  });

  // Display the key/value pairs
  // for (var pair of formData.entries()) {
  //   // console.log(pair[0] + ", " + pair[1]);
  // }

  return formData;
};

export const disableBodyScroll = (disable) => {
  const body = document?.body;
  if (!body) return;
  if (disable) {
    window.scrollTo({ top: 0, behavior: "smooth" });
    body.style.overflow = "hidden";
  } else {
    body.style.overflow = "visible";
  }
};
/**
 * Email validation
 * @param {*} email
 * @returns
 */
export const validateEmail = (email) => {
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) return false;
  return true;
  // return new RegExp(
  //   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  // ).test(email);
};

/**
 * Gets years list from current year onwards
 * @param {Integer} years Number of years
 * @returns {Array} Years List
 */
export const getYearList = (years = 10) => {
  const start = new Date().getFullYear();
  const yearsOptionsList = [];
  for (let index = start; index <= start + years; index++) {
    yearsOptionsList.push(index);
  }
  return yearsOptionsList;
};

/**
 * Get user profile module account type options
 */
export const getUserProfileAccountTypeOptions = () => {
  return [
    {
      label: "Student",
      value: "student",
    },
    {
      label: "Alumni",
      value: "alumni",
    },
  ];
};

/**
 * Get user graduation month type options
 */
export const getUserProfileGraduationMonthOptions = () => {
  return [
    {
      label: "January",
      value: "january",
    },
    {
      label: "February",
      value: "february",
    },
    {
      label: "March",
      value: "march",
    },
    {
      label: "April",
      value: "april",
    },
    {
      label: "May",
      value: "may",
    },
    {
      label: "June",
      value: "june",
    },
    {
      label: "July",
      value: "july",
    },
    {
      label: "August",
      value: "august",
    },
    {
      label: "September",
      value: "september",
    },
    {
      label: "October",
      value: "october",
    },
    {
      label: "November",
      value: "november",
    },
    {
      label: "December",
      value: "december",
    },
  ];
};

export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const capitalizeFirstLetterOnType = (string) => {
  if (string && string.trim().length > 0)
    return string.charAt(0).toUpperCase() + string.slice(1);
  else return "";
};

// Get graduation year options for dropdown. From today to 30 years behind
export const getGraduationYearOptions = () => {
  const currentYear = new Date().getFullYear();
  let graduationYears = [];
  for (let year = currentYear; year > currentYear - 30; year--) {
    graduationYears.push({ label: year, value: year });
  }
  return graduationYears;
};

// Get expected enrollment year dropdown for dropdown. From today to 10 years ahead
export const getExpEnrollmentYearOptions = () => {
  const currentYear = new Date().getFullYear();
  let expEnrollmentYears = [];
  for (let year = currentYear; year < currentYear + 10; year++) {
    expEnrollmentYears.push({ label: year, value: year });
  }
  return expEnrollmentYears;
};

export const getUserApp = () => {
  return process.env.REACT_APP_VERSION ?? "";
};

export const getUserWindow = () => {
  let window_name = "";
  if (!navigator.userAgent) return window_name;

  if (navigator.userAgent.indexOf("Win") !== -1) window_name = "Windows OS";
  else if (navigator.userAgent.indexOf("Mac") !== -1) window_name = "Macintosh";
  else if (navigator.userAgent.indexOf("Linux") !== -1)
    window_name = "Linux OS";
  else if (navigator.userAgent.indexOf("Android") !== -1)
    window_name = "Android OS";
  else if (navigator.userAgent.indexOf("like Mac") !== -1) window_name = "iOS";
  return window_name;
};

export const getUserBrowser = () => {
  let browser_name = "";
  if (!navigator.userAgent) return browser_name;

  browser_name = navigator.userAgent.match(/chrome|chromium|crios/i)
    ? "chrome"
    : navigator.userAgent.match(/firefox|fxios/i)
    ? "firefox"
    : navigator.userAgent.match(/safari/i)
    ? "safari"
    : navigator.userAgent.match(/opr\//i)
    ? "opera"
    : navigator.userAgent.match(/edg/i)
    ? "edge"
    : "";

  return browser_name;
};

export const generateDisplayName = (name) => {
  const words = name.split(" ");
  if (words?.length > 1) {
    return `${words[0]} ${words[1][0].toUpperCase()}.`;
  }
  return words[0];
};

/**
 * Method takes in the full name of a user and returns only first name
 * @param {String} fullName of user
 * @returns First name of user
 */
export const extractFirstName = (fullName) => {
  if (fullName && fullName.length > 0) {
    return fullName.split(" ")[0];
  } else {
    return "";
  }
};

export const getCapitalizedHeader = (str = "") => {
  return str?.length > 0 ? str?.charAt(0)?.toUpperCase() + str?.slice(1) : "";
};

export const generateFirstName = (full_name) => {
  const words = full_name.split(" ");
  if (words && words.length >= 1) {
    return words[0];
  }
  return full_name;
};

export const getTokenFromURL = (path) => {
  if (path.includes(ROUTES.SCHOOL)) {
    return path.split("/")?.[2] ?? false;
  } else return false;
};

export const addMicrosoftClarity = () => {
  (function (c, l, a, r, i, t, y) {
    console.log();
    c[a] =
      c[a] ||
      function () {
        (c[a].q = c[a].q || []).push(arguments);
      };
    t = l.createElement(r);
    t.async = 1;
    t.src = "https://www.clarity.ms/tag/" + i;
    y = l.getElementsByTagName(r)[0];
    y.parentNode.insertBefore(t, y);
  })(window, document, "clarity", "script", MICROSOFT_CLARITY_ID);
};

export const setMSClarityTagsForUserProfileData = (user) => {
  if (window?.clarity) {
    window.clarity("set", "user_id", user?.id?.toString());
    window.clarity("set", "user_email", user?.email ?? "");
    window.clarity("set", "user_account_type", user?.account_type ?? "");
    window.clarity("set", "user_first_name", user?.first_name ?? "");
    window.clarity("set", "user_last_name", user?.last_name ?? "");
    window.clarity("set", "user_nationality", user?.nationality ?? "");
    window.clarity("set", "user_degree", user?.educations?.[0]?.degree ?? "");
    window.clarity("set", "user_major", user?.educations?.[0]?.major ?? "");
    window.clarity(
      "set",
      "user_graduation_year",
      user?.educations?.[0]?.graduation_year ?? ""
    );
    window.clarity(
      "set",
      "phone_number",
      user?.country_code
        ? `${user?.country_code} ${user?.phone_number}`
        : user?.phone_number
    );
  }
};

export const setMSClarityTagsAfterLogin = (user, source) => {
  if (window.clarity) {
    window.clarity("identify", user.id.toString());
    window.clarity("set", "source", source);

    setMSClarityTagsForUserProfileData(user);
  }
};

export const setMSClarityTagsAfterAddingToken = (user, school_id) => {
  if (window.clarity) {
    window.clarity("identify", user.id.toString());

    // When logged in with token
    if (school_id) window.clarity("set", "school_id", school_id);

    setMSClarityTagsForUserProfileData(user);
  }
};

export const setMSClarityTagsOnLogin = (email) => {
  if (window.clarity) {
    window.clarity("set", "user_email", email);
  }
};

export const setMSClarityTagsOnSignUp = (user) => {
  if (window.clarity) {
    window.clarity("identify", user.id.toString());

    window.clarity("set", "user_id", user?.id?.toString());
    window.clarity("set", "user_email", user?.email ?? "");
    window.clarity("set", "user_account_type", user?.account_type ?? "");
  }
};

export const wipeOutUserData = () => {
  console.log("wipeOutUserData");
  sessionStorage.clear();

  // Preserve per_day_app_load even after logoout/unauthorized access
  const storedAppLoad = localStorage.getItem("per_day_app_load");
  const storedSavedRoute = localStorage.getItem("saved_route");
  const chatInfoStatus = localStorage.getItem("chat_info_status");
  const storedFeedbackDialogTimestamp = localStorage.getItem(
    `feedback_dialog_timestamp`
  );
  localStorage.clear();
  if (storedAppLoad) localStorage.setItem("per_day_app_load", storedAppLoad);
  if (storedSavedRoute)
    localStorage.setItem("saved_route", JSON.parse(storedSavedRoute));
  if (chatInfoStatus) localStorage.setItem("chat_info_status", chatInfoStatus);
  if (storedFeedbackDialogTimestamp)
    localStorage.setItem(
      `feedback_dialog_timestamp`,
      storedFeedbackDialogTimestamp
    );

  if (typeof window?.kommunicate?.logout === "function")
    window.kommunicate.logout(); // Logout from chatbot}

  window.location.replace("/login");
};

export const groupBy = (arr, criteria) => {
  return arr.reduce(function (obj, item) {
    // Check if the criteria is a function to run on the item or a property of it
    let key = typeof criteria === "function" ? criteria(item) : item[criteria];

    // If the key doesn't exist yet, create it
    if (!obj.hasOwnProperty(key)) {
      obj[key] = [];
    }

    // Push the value to the object
    obj[key].push(item);

    // Return the object to the next item in the loop
    return obj;
  }, {});
};

// Computes and formats delivery time of message
export const deliveryTime = (time) => {
  if (time) {
    return new Date(time)
      .toLocaleString("default", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
      .toLocaleLowerCase();
  } else return "";
};

export const isElementVisibleOnDOM = (element) => {
  if (
    element?.offsetWidth ||
    element?.offsetHeight ||
    element?.getClientRects()?.length
  )
    return true;
  else return false;
};

export const setBodyBackground = (color = "#f8faff") => {
  const body = document?.body;
  if (!body) return;
  else {
    body.style.setProperty("background", color, "important");
  }
};

export const UNIVERSITY_WIDGET_SOURCE = "main-widget";
export const UNIVERSITY_CHAT_LIST_SOURCE = "chat-carousel";
export const UNIVERSITY_CHAT_POPUP_SOURCE = "chat-pop-up";

/**
 * To fetch UTM parameters from current URL
 * @param {*} defaultValues any default value if parameter is not present
 * @returns
 */
export const fetchUTMParamsFromURL = (defaultValues = {}) => {
  const searchParams = new URLSearchParams(window?.location?.search);

  const UTM_PARAMS = [
    "utm_source",
    "utm_campaign",
    "utm_medium",
    "utm_term",
    "utm_content",
  ];

  const params = {};
  // Fetch utm search parameters from current URL
  UTM_PARAMS.forEach((param) => {
    if (searchParams.get(param) || defaultValues[param])
      params[param] = searchParams.get(param) ?? defaultValues[param] ?? "";
  });
  return params;
};

/**
 * To get customized university visit URL with school route and utm parameters along with any additional parameters
 * @param {*} token
 * @param {*} utm_source
 * @param {*} extraParams
 * @returns
 */
export const getUniversityVisitURL = (token, utm_source, extraParams = {}) => {
  const params = {
    ...extraParams,
    ...fetchUTMParamsFromURL({ utm_source }),
  };
  return `${createQueryStringURL(`${ROUTES.SCHOOL}/${token}`, params)}`;
};

/**
 * Get common fields of user_device for login & signup
 * @param {*} device_token firebase token if present
 * @returns
 */
export const getCommonUserDeviceFields = () => {
  const fields = {
    platform: 302,
    device_name: getUserBrowser(),
    app_version: getUserApp(),
    os_version: getUserWindow(),
  };

  // Add firebase token from redux state
  let state = store.getState();
  const firebaseToken = state?.userState?.firebaseToken
    ? state?.userState?.firebaseToken
    : null;
  if (firebaseToken) fields.device_token = firebaseToken;
  return fields;
};

/**
 * Get common fields of user_device & utm_params for login & signup
 * @returns all common sign in fields
 */
export const getCommonSignInFields = () => {
  return {
    user_device: {
      ...getCommonUserDeviceFields(),
    },
    utm_params: {
      ...fetchUTMParamsFromURL(),
    },
  };
};

/**
 * Login route and utm parameters with extra params
 * @param {*} object with extra params and default source value for UTM
 * @returns
 */
export const getLoginURLWithParams = ({
  utm_source = null,
  ...extraParams
} = {}) => {
  const params = {
    ...extraParams,
    ...fetchUTMParamsFromURL(!isNull(utm_source) ? { utm_source } : {}),
  };
  return `${createQueryStringURL(`/login`, params)}`;
};

/**
 * These route types can be used to redirect user after login/signup
 * When it is mandatory to have fixed path to load onboarding screen like widget signup/invited signup
 */
export const ROUTE_TYPES = {
  DASHBOARD: "dashboard",
  RESOURCE: "resource",
  SERVICE: "service",
  WEBINAR: "webinar",
  COMMUNITY: "community",
  CHAT: "chat",
  EMPLOYMENT_DATA: "employment_data",
};

export const getRouteTypePath = (ROUTES, path, isWidgetUser) => {
  const paths = {
    [ROUTE_TYPES.DASHBOARD]: ROUTES.DASHBOARD,
    [ROUTE_TYPES.RESOURCE]: ROUTES.DISCOVER.RESOURCES,
    [ROUTE_TYPES.SERVICE]: ROUTES.DISCOVER.STUDENT_SERVICES,
    [ROUTE_TYPES.WEBINAR]: ROUTES.DISCOVER.WEBINARS,
    [ROUTE_TYPES.COMMUNITY]: ROUTES.COMMUNITY.TOPICS,
    [ROUTE_TYPES.CHAT]: ROUTES.CHAT.AMBASSADORS,
    [ROUTE_TYPES.EMPLOYMENT_DATA]: ROUTES.DISCOVER.EMPLOYMENT_DATA_HUB,
  };

  // If not widget user then don't use saved route instead prioritize dashboard route
  return isWidgetUser && paths[path] ? paths[path] : ROUTES.DASHBOARD;
};

export const getRedirectRouteFromURL = (isWidgetUser) => {
  // Fetch saved routes from redux state
  let state = store.getState();
  const savedRoute = state?.preservedState?.savedRoute ?? null;

  const currentPath = window.location.pathname;
  const routeParams = savedRoute ?? fetchRouteParamsFromURL();

  let path =
    currentPath.includes(ROUTES.LOGIN) ||
    currentPath.includes(ROUTES.SIGN_UP) ||
    currentPath.includes(ROUTES.INVITED_SIGN_UP) ||
    currentPath.includes(ROUTES.AMBASSADOR_SIGN_UP) ||
    currentPath.includes(ROUTES.AMBASSADOR_ACCEPT_INVITE)
      ? ROUTES.DASHBOARD
      : [...UNPROTECTED_ROUTES, ...WIDGET_PATHS].includes(currentPath) ||
        currentPath.includes(ROUTES.SCHOOL) || // with route param for token
        currentPath.includes(ROUTES.UNIVERSITY_WIDGET) // with route param for token
      ? // If no saved route found then prioritize current route
        currentPath + window.location.search
      : ROUTES.DASHBOARD;

  if (routeParams && !isEmpty(routeParams)) {
    const {
      route_type,
      route_path = ROUTES.DASHBOARD,
      ...additionalRouteParams
    } = routeParams;

    if (route_type) {
      path =
        additionalRouteParams && !isEmpty(additionalRouteParams)
          ? `${createQueryStringURL(
              getRouteTypePath(ROUTES, route_type, isWidgetUser),
              additionalRouteParams
            )}`
          : getRouteTypePath(ROUTES, route_type, isWidgetUser);
    } else {
      path =
        additionalRouteParams && !isEmpty(additionalRouteParams)
          ? `${createQueryStringURL(route_path, additionalRouteParams)}`
          : route_path === "/"
          ? ROUTES.DASHBOARD
          : route_path;
    }
  }
  return path;
};

export const fetchRouteParamsFromURL = (defaultValues = {}) => {
  const searchParams = new URLSearchParams(window?.location?.search);

  const ROUTE_PARAMS = ["route_type", "route_id", "route_view"];

  const params = {};
  // Fetch route parameters from current URL
  ROUTE_PARAMS.forEach((param) => {
    if (searchParams.get(param) || defaultValues[param])
      params[param] = searchParams.get(param) ?? defaultValues[param] ?? "";
  });

  // Add current path of the URL if its any protected path after login to be accessed after login/signup
  const route_path = window.location.pathname;
  if (
    ![...UNPROTECTED_ROUTES, ...WIDGET_PATHS].includes(route_path) &&
    !route_path.includes(ROUTES.SCHOOL) && // with route param for token
    !route_path.includes(ROUTES.UNIVERSITY_WIDGET) // with route param for token
  ) {
    params.route_path = route_path;
  }

  // Directly hitting school widget URL so no route search params present then need to redirect to dashboard after login/signup
  if (route_path.includes(ROUTES.SCHOOL) && isEmpty(params)) {
    params.route_type = ROUTE_TYPES.DASHBOARD;
  }
  console.log("Fetched saved route -> " + JSON.stringify(params));
  return params;
};

/**
 * Method checks if pageURL is permissible to be accessed
 * @param {Object} permissions from Redux store
 * @param {String} pageURL of module
 * @returns {Boolean} status
 */
export const isPageAccessible = (permissions = {}, pageURL = null) => {
  if (!isEmpty(permissions) && !isNull(pageURL)) {
    return !(
      (ROUTES.CHAT.AMBASSADORS.includes(pageURL) &&
        permissions?.chat === false) ||
      (ROUTES.MESSAGES.includes(pageURL) && permissions?.chat === false) ||
      (ROUTES.COMMUNITY.TOPICS.includes(pageURL) &&
        permissions?.topics === false) ||
      (ROUTES.DISCOVER.EMPLOYMENT_DATA_HUB.includes(pageURL) &&
        permissions?.employment_data === false) ||
      (ROUTES.DISCOVER.WEBINARS.includes(pageURL) &&
        permissions?.webinar === false) ||
      (ROUTES.DISCOVER.RESOURCES.includes(pageURL) &&
        permissions?.resources === false) ||
      (ROUTES.DISCOVER.STUDENT_SERVICES.includes(pageURL) &&
        permissions?.student_services === false) ||
      (ROUTES.TOOLS.CHECKLISTS.includes(pageURL) &&
        permissions?.checklist === false)
    );
  }
};

export const DEFAULT_RESOURCE_FILTER_OPTIONS = [
  { value: "pdf", label: "PDF" },
  { value: "website", label: "Website" },
  { value: "video", label: "Video" },
  { value: "podcast", label: "Podcast" },
  { value: "doc", label: "Document" },
  { value: "ppt", label: "PowerPoint" },
  { value: "image", label: "Image" },
];

// Parse API filter type array data to object with labels
export const parseResourceAPIFilters = (filters) => {
  if (!filters) return [];
  let parsedFilters = [];
  filters.forEach((filter) => {
    switch (filter) {
      case "pdf":
        parsedFilters.push({ value: "pdf", label: "PDF" });
        break;
      case "website":
        parsedFilters.push({ value: "website", label: "Website" });
        break;
      case "video":
        parsedFilters.push({ value: "video", label: "Video" });
        break;
      case "podcast":
        parsedFilters.push({ value: "podcast", label: "Podcast" });
        break;
      case "doc":
        parsedFilters.push({ value: "doc", label: "Document" });
        break;
      case "ppt":
        parsedFilters.push({ value: "ppt", label: "PowerPoint" });
        break;
      case "image":
        parsedFilters.push({ value: "image", label: "Image" });
        break;
      case "youtube":
        parsedFilters.push({ value: "youtube", label: "YouTube Video" });
        break;
      case "excel_sheet":
        parsedFilters.push({ value: "excel_sheet", label: "Excel Sheet" });
        break;
      default:
        parsedFilters.push({ value: filter, label: filter });
    }
  });
  return parsedFilters;
};

export const getLocalTzAbbr = () => {
  //initialization:
  const eventDate = moment()?.local()?.format(); //any dynamic value
  const visitorTz = momentTz?.tz?.guess();
  const momentDateTime = moment?.utc(eventDate)?.tz(visitorTz);

  //compare first 3 letters to check if abbr available for this timezone:
  const z1 = momentDateTime?.format("Z"); //eg. '+06:00'
  const z2 = momentDateTime?.format("z"); //eg. PDT|AEST|IST|'+06'
  const tzAbbr = z1.substring(0, 3) === z2.substring(0, 3) ? `GMT ${z1}` : z2;

  return tzAbbr;
};

export const calculateLocalEndTimeFromEpoch = (
  epochTime,
  number,
  add,
  showSpace = true
) => {
  return moment
    .unix(epochTime)
    .local()
    .add(number, add)
    .format(showSpace ? "hh:mm a" : "hh:mma");
};

export const computeNetworkUserIntro = (
  intro = "",
  user_type = null,
  major = "",
  degree = "",
  school_name = ""
) => {
  let introText = "";
  if (validateAttribute(intro)) {
    introText = intro;
  } else if (
    (user_type === "Student" || user_type === "Alumni") &&
    validateAttribute(major) &&
    validateAttribute(degree) &&
    validateAttribute(school_name)
  ) {
    introText = `I ${
      user_type === "Alumni" ? "have completed" : "am currently persuing my"
    } ${degree} in ${major} at ${school_name}`;
  } else if (user_type === "Admin" || user_type === "Staff") {
    introText = `University Admin at ${school_name}`;
  }
  return introText;
};
