import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { createModuleStyleExtractor } from "../../../../utils/css";
import styles from "./WebinarsV2.module.scss";
import { getWebinarsData, getMyWebinarsData } from "../../../../api/webinars";
import { ROUTES } from "../../../../routes";
import { Button, ButtonModifier } from "../../../atoms/Button";
import MuiTextbox from "../../../atoms/MuiTextbox/MuiTextbox";
import { Col } from "../../../helpers/Grid/Col";
import WebinarsCalendarSidebarV2 from "../../../molecules/WebinarsCalendarSidebar/V2/WebinarsCalendarSidebarV2";
import WebinarsCalendarV2 from "../../../molecules/WebinarsCalendar/V2/WebinarsCalendarV2";
import { Modal } from "../../../atoms/Modal/Modal";
import { ModelHeader } from "../../../atoms/Modal/ModelHeader/ModelHeader";
import { ModalBody } from "../../../atoms/Modal/ModalBody/ModalBody";
import {
  calculateLocalEndTimeFromEpoch,
  getLocalTzAbbr,
} from "../../../../utils/common";
import { isNull } from "../../../../utils/validations";
import WebinarItem from "../../../molecules/WebinarItem/WebinarItem";
import ListingEffect from "../../../atoms/ListingEffect/ListingEffect";

import WebinarIcon from "../../../../assets/images/Webinar.png";
import SmallPersonIcon from "../../../../assets/Icons/smallPersonIcon.svg";
import circleCheck from "../../../../assets/Icons/f-circle-check.svg";
import discoverNoPeople from "../../../../assets/images/discover_no_people.svg";
import FaceliftSearchIcon from "../../../../assets/Icons/FaceliftSearchIcon";

const cx = createModuleStyleExtractor(styles);
const tabOptions = ["Live", "On-Demand", "Registered"];

export default function WebinarsV2() {
  const navigate = useNavigate();
  const [webinarsLoader, setWebinarsLoader] = useState(false);
  const [upcomingWebinars, setUpcomingWebinars] = useState([]);
  const [pastWebinars, setPastWebinars] = useState([]);
  const [allWebinars, setAllWebinars] = useState([]);
  const [webinarCategories, setWebinarCategories] = useState([]);
  const [selectedWebinar, setSelectedWebinar] = useState(null);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [myWebinars, setMyWebinars] = useState([]);

  const [tab, setTab] = useState(tabOptions[0]);
  const [searchText, setSearchText] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);

  const { selectedSchool = {}, permissions = {} } = useSelector(
    (state) => state.userState
  );

  useEffect(() => {
    const getMyWebinars = async () => {
      const responseMyWebinars = await getMyWebinarsData(selectedSchool.id);
      if (responseMyWebinars.success) {
        setMyWebinars(responseMyWebinars.data.my_webinars_list);
      }
    };

    getMyWebinars();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSchool]);

  useEffect(() => {
    // Do not allow to access if feature permission is not granted
    if (!permissions["webinar"]) {
      navigate(ROUTES.DASHBOARD);
    }
  }, [permissions, navigate]);

  const getWebinars = async ({
    search = "",
    categories = [],
    initialLoad = false,
  } = {}) => {
    setWebinarsLoader(true);
    const response = await getWebinarsData(
      search,
      categories,
      selectedSchool.id
    );
    if (response.success) {
      setUpcomingWebinars(
        response.data?.upcoming_webinars ? response.data.upcoming_webinars : []
      );
      setPastWebinars(
        response.data?.past_webinars ? response.data.past_webinars : []
      );

      if (initialLoad)
        setWebinarCategories(
          response.data?.categories ? response.data.categories : []
        );
    }
    setWebinarsLoader(false);
  };

  // Tracks past and upcoming webinars and combines them in local state
  useEffect(() => {
    setAllWebinars(pastWebinars.concat(upcomingWebinars));
  }, [pastWebinars, upcomingWebinars]);

  useEffect(() => {
    getWebinars({ initialLoad: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSchool]);

  const handleViewDetailsClick = (webinarId) => {
    setSelectedWebinar(
      allWebinars.filter((webinar) => webinar.id === parseInt(webinarId))[0]
    );
    setShowDetailModal(!showDetailModal);
  };

  const handleTabChange = (tab = tabOptions[0]) => {
    setTab(tab);
  };

  const handleFilterClick = (categoryId) => {
    let _selectedCategories = [...selectedCategories];

    if (_selectedCategories.includes(categoryId)) {
      const selectedIndex = _selectedCategories.findIndex(
        (id) => id === categoryId
      );
      _selectedCategories.splice(selectedIndex, 1);
    } else {
      _selectedCategories.push(categoryId);
    }
    setSelectedCategories([..._selectedCategories]);

    getWebinars({
      search: searchText,
      categories: _selectedCategories,
    });
  };

  const handleClearAllFilter = () => {
    setSearchText("");
    setSelectedCategories([]);
    getWebinars();
  };

  const handleSearch = () => {
    getWebinars({
      search: searchText,
      categories: selectedCategories,
    });
  };

  // Clear filters upon tab change and recall api
  useEffect(() => {
    handleClearAllFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  return (
    <div className={cx("o-webinars")}>
      <h2 className={cx("o-webinars__title")}>Explore our Webinars</h2>
      <div className={cx("o-webinars__tabs")}>
        <Button
          modifier={
            tab === tabOptions[0]
              ? ButtonModifier.WEBINAR_TAB_ACTIVE_BUTTON
              : ButtonModifier.WEBINAR_TAB_BUTTON
          }
          onClick={() => handleTabChange(tabOptions[0])}
        >
          {tabOptions[0]}
        </Button>
        <Button
          modifier={
            tab === tabOptions[1]
              ? ButtonModifier.WEBINAR_TAB_ACTIVE_BUTTON
              : ButtonModifier.WEBINAR_TAB_BUTTON
          }
          onClick={() => handleTabChange(tabOptions[1])}
        >
          {tabOptions[1]}
        </Button>
        <Button
          modifier={
            tab === tabOptions[2]
              ? ButtonModifier.WEBINAR_TAB_ACTIVE_BUTTON
              : ButtonModifier.WEBINAR_TAB_BUTTON
          }
          onClick={() => handleTabChange(tabOptions[2])}
        >
          {tabOptions[2]}
        </Button>
      </div>
      {(tab === tabOptions[0] || tab === tabOptions[1]) && (
        <div className={cx("o-webinars__filters")}>
          <div className={cx("o-webinars__filters__search")}>
            <MuiTextbox
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              name="search"
              placeholder="Search keyword"
              height={50}
              width={220}
              maxLength={75}
            />
            <FaceliftSearchIcon onClick={handleSearch} />
          </div>
          {webinarCategories?.length > 0 && (
            <div className={cx("o-webinars__filters__categories")}>
              <h4>Filter</h4>
              {webinarCategories?.map((category, index) => (
                <div
                  className={cx([
                    "o-webinars__filters__categories__tag",
                    selectedCategories.includes(category?.id)
                      ? "o-webinars__filters__categories__tag--active"
                      : "",
                  ])}
                  key={category?.id || index}
                >
                  <span
                    onClick={() => {
                      handleFilterClick(category.id);
                    }}
                  >
                    {category?.category_name}
                  </span>
                </div>
              ))}
            </div>
          )}
          {(searchText?.length > 0 || selectedCategories.length > 0) && (
            <div className={cx("o-webinars__filters__clear-all")}>
              <span onClick={handleClearAllFilter}>Clear all</span>
            </div>
          )}
        </div>
      )}
      <div
        className={cx([
          "o-webinars__listing",
          tab === tabOptions[2] ? "o-webinars__listing--calendar" : "",
        ])}
      >
        {webinarsLoader ? (
          <ListingEffect count={5} sideSpace={false} />
        ) : !webinarsLoader &&
          tab === tabOptions[0] &&
          upcomingWebinars?.length === 0 ? (
          <div className={cx("o-webinars__listing__empty-card")}>
            <img src={discoverNoPeople} alt="discoverNoPeople" />
            <h3>Looks like there are no webinars with this search.</h3>
            <h4>Try expanding your search to discover other webinars!</h4>
          </div>
        ) : !webinarsLoader &&
          tab === tabOptions[0] &&
          upcomingWebinars?.length > 0 ? (
          <>
            {upcomingWebinars?.map((webinarData, index) => (
              <div key={index}>
                <WebinarItem
                  data={webinarData}
                  type="upcoming"
                  myWebinars={myWebinars}
                />
              </div>
            ))}
          </>
        ) : !webinarsLoader &&
          tab === tabOptions[1] &&
          pastWebinars?.length === 0 ? (
          <div className={cx("o-webinars__listing__empty-card")}>
            <img src={discoverNoPeople} alt="discoverNoPeople" />
            <h3>Looks like there are no past webinars.</h3>
            <h4>Try expanding your search to discover other webinars!</h4>
          </div>
        ) : !webinarsLoader &&
          tab === tabOptions[1] &&
          pastWebinars?.length > 0 ? (
          <>
            {pastWebinars?.map((webinarData, index) => (
              <div key={index}>
                <WebinarItem data={webinarData} type="past" />
              </div>
            ))}
          </>
        ) : !webinarsLoader &&
          tab === tabOptions[2] &&
          myWebinars?.length === 0 ? (
          <div className={cx("o-webinars__listing__empty-card")}>
            <img src={discoverNoPeople} alt="discoverNoPeople" />
            <h3>Looks like you haven't registered for any live webinars.</h3>
            <h4>All your registered webinars will show here.</h4>
          </div>
        ) : !webinarsLoader &&
          tab === tabOptions[2] &&
          myWebinars?.length > 0 ? (
          <div className={cx("o-webinars__listing__my-webinar")}>
            <Col xs={12} sm={12} md={5} lg={5}>
              <WebinarsCalendarSidebarV2
                myWebinars={myWebinars}
                handleViewDetailsClick={handleViewDetailsClick}
              />
            </Col>
            <Col xs={12} sm={12} md={7} lg={7}>
              <WebinarsCalendarV2
                myWebinars={myWebinars}
                handleViewDetailsClick={handleViewDetailsClick}
              />
            </Col>
          </div>
        ) : (
          <></>
        )}
      </div>

      {/* Webinar view details modal */}
      {selectedWebinar && showDetailModal && (
        <div className={cx("o-webinars__modal-details")}>
          <Modal
            show={showDetailModal}
            onClose={() => setShowDetailModal(!showDetailModal)}
            preserveBodyScroll={true}
          >
            <ModelHeader title={""} />
            <ModalBody>
              <div className={cx("o-webinars__modal-details__body")}>
                <div className={cx("o-webinars__modal-details__body__image")}>
                  {selectedWebinar.webinar_image &&
                  selectedWebinar.webinar_image.length > 0 ? (
                    <img src={selectedWebinar.webinar_image} alt="Webinar" />
                  ) : (
                    <img src={WebinarIcon} alt="Webinar" />
                  )}
                  {selectedWebinar.webinar_category_name &&
                    selectedWebinar.webinar_category_name !== "undefined" && (
                      <div
                        className={cx(
                          "o-webinars__modal-details__body__image__tag"
                        )}
                      >
                        {selectedWebinar.webinar_category_name}
                      </div>
                    )}
                </div>
                <div
                  className={cx(
                    "o-webinars__modal-details__body__header-primary"
                  )}
                >
                  {selectedWebinar.name}{" "}
                </div>
                <div
                  className={cx("o-webinars__modal-details__body__description")}
                >
                  {selectedWebinar.description}
                </div>
                {selectedWebinar.presenter &&
                  selectedWebinar.presenter.length > 0 && (
                    <h5
                      className={cx(
                        "o-webinars__modal-details__body__presenter"
                      )}
                    >
                      <img src={SmallPersonIcon} alt="Small person" />
                      {selectedWebinar.presenter}
                    </h5>
                  )}
                <h3
                  className={cx("o-webinars__modal-details__body__date-time")}
                >
                  {moment
                    .unix(selectedWebinar?.epoch_time)
                    .local()
                    .format("dddd, MMMM Do, YYYY")}
                </h3>
                <h3
                  className={cx("o-webinars__modal-details__body__date-time")}
                >
                  {`${moment
                    .unix(selectedWebinar?.epoch_time)
                    .local()
                    .format("hh:mm a")} - ${calculateLocalEndTimeFromEpoch(
                    selectedWebinar?.epoch_time,
                    selectedWebinar?.duration &&
                      !isNull(selectedWebinar.duration)
                      ? selectedWebinar.duration.split(" ")[0]
                      : 0,
                    "minutes"
                  )}`}{" "}
                  &nbsp; ({getLocalTzAbbr()})
                </h3>
                <div className={cx("o-webinars__modal-details__body__button")}>
                  <Button
                    modifier={ButtonModifier.WEBINAR_BUTTON_REGISTERED}
                    disabled={true}
                  >
                    <div
                      className={cx(
                        "o-webinars__modal-details__body__button__with-icon"
                      )}
                    >
                      <span>Registered</span>
                      <img src={circleCheck} alt="check" />
                    </div>
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
      )}
    </div>
  );
}
