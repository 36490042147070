import React, { memo, useEffect, useState } from "react";
import { ShimmerPostItem } from "react-shimmer-effects";
import Slider from "react-slick";

import { getStudentsList } from "../../../api/widget";
import { createModuleStyleExtractor } from "../../../utils/css";
import { isNull } from "../../../utils/validations";
import {
  getUniversityVisitURL,
  ROUTE_TYPES,
  UNIVERSITY_CHAT_LIST_SOURCE,
} from "../../../utils/common";
import { Col } from "../../helpers/Grid/Col";
import { Row } from "../../helpers/Grid/Row";
import styles from "./WidgetChatList.module.scss";
import WidgetChatlistCard from "../../atoms/WidgetChatlistCard/WidgetChatlistCard";

import nostudentsImg from "../../../assets/images/discover_no_contacts.svg";
import { INTERSTRIDE_LOGO_URL } from "../../../utils/constants";

const cx = createModuleStyleExtractor(styles);

const WidgetChatList = ({ schoolData = {} }) => {
  const [students, setStudents] = useState(null);
  const [studentsLoader, setStudentsLoader] = useState(true);

  useEffect(() => {
    const fetchStudentsList = async () => {
      setStudentsLoader(true);
      const response = await getStudentsList({}, schoolData?.token);
      if (response.success && response.data) {
        setStudents(response.data.users);
        setStudentsLoader(false);
      } else {
        setStudents([]);
        setStudentsLoader(false);
      }
    };
    fetchStudentsList();
  }, [schoolData?.token]);

  let settings = {
    accessibility: true,
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className={cx("m-widget-chatlist")}>
        <h2 className={cx("m-widget-chatlist__title")}>
          Chat with our students
        </h2>
        {studentsLoader ? (
          <Row>
            {[...Array(4)].map((_, index) => (
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={4}
                key={`student_${index}`}
                styles={{ marginBottom: "10px" }}
              >
                <ShimmerPostItem
                  card
                  title
                  cta
                  imageType="circular"
                  imageWidth={70}
                  imageHeight={70}
                  contentCenter
                />
              </Col>
            ))}
          </Row>
        ) : (
          <>
            {!isNull(students) && students?.length === 0 ? (
              <div className={cx("m-widget-chatlist__empty")}>
                <h3>There are no students available at this moment.</h3>
                <h3>Please try again later.</h3>
                <img src={nostudentsImg} alt="No students" />
              </div>
            ) : (
              <div className={cx("m-widget-chatlist__student-cards")}>
                <div className="widget-chatlist-slider">
                  <Slider {...settings}>
                    {!isNull(students) &&
                      students.map((user) => (
                        <div
                          key={user.id}
                          className={cx(
                            "m-widget-chatlist__student-cards__wrapper"
                          )}
                        >
                          <WidgetChatlistCard
                            user={user}
                            showUserType
                            onChatClick={() =>
                              window.open(
                                getUniversityVisitURL(
                                  schoolData.token,
                                  UNIVERSITY_CHAT_LIST_SOURCE,
                                  {
                                    route_type: ROUTE_TYPES.CHAT,
                                    route_id: user.id,
                                  }
                                )
                              )
                            }
                          />
                        </div>
                      ))}
                  </Slider>
                  <div className="poweredby-logo">
                    <h3>Powered by</h3>
                    <img
                      src={INTERSTRIDE_LOGO_URL}
                      alt="interstride logo"
                    ></img>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default memo(WidgetChatList);
