import React, { memo } from "react";

import { createModuleStyleExtractor } from "../../../utils/css";
import { Button, ButtonModifier } from "../../atoms/Button";
import styles from "./MasterclassCourseSlide.module.scss";

import MasterclassImage from "../../../assets/images/masterclass-ad.png";

const cx = createModuleStyleExtractor(styles);

const MasterclassCourseSlide = () => {
  return (
    <div className={cx("m-masterclass-course-slide")}>
      <img
        className={cx("m-masterclass-course-slide__image")}
        src={MasterclassImage}
        alt="Illustration"
      />
      <div className={cx("m-masterclass-course-slide__title")}>
        Free course on US College Admissions & Applications
      </div>
      <div className={cx("m-masterclass-course-slide__subtitle")}>
        Use code ADMISSIONS at checkout
      </div>

      <Button
        modifier={ButtonModifier.SLIDER_ACTION}
        onClick={() =>
          window.open(
            "https://courses.interstride.com/course/us-admissions",
            "_blank"
          )
        }
      >
        Get the course
      </Button>
    </div>
  );
};
export default memo(MasterclassCourseSlide);
